<div class="d-flex" [class.fd-rr]="template.sidebarPosition === 'right'">
  @if (template.type === TemplateTypes.SIDEBAR) {
    <div
      class="box"
      nz-resizable
      (nzResize)="onResizeSidebar($event)"
      [nzMaxWidth]="template.settings.sidebar.maxWidth"
      [nzMinWidth]="template.settings.sidebar.minWidth"
      [style.height.px]="20"
      [style.width.px]="template.settings.sidebar.with">
      <nz-resize-handle [nzDirection]="template.sidebarPosition === 'right' ? 'left' : 'right'">
        <div
          class="right-wrap"
          [nz-tooltip]="'Resize Sidebar ' + template.settings.sidebar.with + ' (Default 306)'"
          id="introjs_resize-icon">
          <i class="ph ph-split-horizontal"></i>
        </div>
      </nz-resize-handle>
    </div>
  }
</div>

@if (template.type === TemplateTypes.TWO_ROWS) {
  <div
    class="box ml-50"
    nz-resizable
    (nzResize)="onResizeTwoRows($event)"
    [nzMaxWidth]="template.settings.twoRows.maxWidth"
    [nzMinWidth]="template.settings.twoRows.minWidth"
    [style.height.px]="20"
    [style.width.px]="template.settings.twoRows.with">
    <nz-resize-handle nzDirection="right">
      <div
        class="right-wrap"
        [nz-tooltip]="'Resize Sections ' + template.settings.twoRows.with + ' (Middle 442)'"
        id="introjs_resize-icon">
        <i class="ph ph-split-horizontal"></i>
      </div>
    </nz-resize-handle>
  </div>
}
