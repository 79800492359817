<div class="template-layouts" id="introjs_section-tm-layouts">
  <h3 class="d-flex ai-c mb-24">
    {{ 'builder.template_layouts' | translate }}
    <i
      class="ph ph-question ml-4 c-pointer"
      [nz-popover]="'tooltips.3_layouts' | translate"
      [nzPopoverContent]="TmTypeInfo"
      nzPopoverPlacement="bottom"
    ></i>
  </h3>

  <div class="d-flex jc-sb">
    @for (type of templateTypes; track type.src) {
      <img
        [ngSrc]="type.src"
        width="100"
        height="140"
        class="c-pointer"
        [alt]="type.name"
        [nz-tooltip]="type.name | translate"
        [class.active]="template.type === type.type"
        (click)="onChangeTemplateType(type.type)">
    }
  </div>
</div>

<ng-template #TmTypeInfo>
  <div class="fs-14">
    <ul class="pl-20" style="list-style: initial">
      <li>{{ 'builder.one_column_template' | translate }}</li>
      <li>{{ 'builder.two_columns_template' | translate }}</li>
      <li>{{ 'builder.template_with_sidebar' | translate }}</li>
    </ul>

    <p class="mt-10">{{ 'builder.switch_between_layouts' | translate }}</p>
  </div>
</ng-template>
