<div class="img-part-wrapper" (sfClickOutside)="onClickOutsideImg()">
  <input #FileInputRef type="file" (input)="onSelectImage($event)">

  @if (imgBannerState) {
    <div class="tm-st-banner">
      <div class="new-entry" (click)="onUploadFile(FileInputRef)">
        <i class="ph ph-plus-circle"></i>
        Upload Photo
      </div>

      <i
        class="ph ph-gear"
        (click)="openImgSettings()"
        [class.active]="settingsSelected"
      ></i>

      <i class="ph ph-trash-simple" (click)="onRemoveTemplateImage()"></i>

      <div class="tm-st-banner-settings img-settings" *ngIf="settingsSelected">
        @for (shape of imgBorderShapes; track $index) {
          <div class="img-shape-icon-wr">
            @if (shape.className !== 'initial') {
              <i class="ph ph-star" [nz-tooltip]="'tooltips.paid' | translate"></i>
            }
            <i
              class="ph-bold ph-user img-shape {{shape.className}}"
              [class.active]="template.img.settings.selectedShape.value === shape.className"
              (click)="onSelectImgShape(shape)"
            ></i>
          </div>
        }
      </div>
    </div>
  }


  <div (click)="imgBannerState = true">
    <div class="img-part {{template.img.settings.selectedShape.value}}">
      @if (!appStateService.user?.image && !template.img.src) {
        <div class="empty-image">
          <svg
            width="132"
            height="132"
            viewBox="0 0 132 132"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="66" cy="66" r="65.5" [attr.stroke]="stroke"/>
            <path
              [attr.stroke]="stroke"
              d="M86 65V71.2C86 75.6804 86 77.9206 85.1281 79.6319C84.3611 81.1372 83.1372 82.3611 81.6319 83.1281C79.9206 84 77.6804 84 73.2 84H58.8C54.3196 84 52.0794 84 50.3681 83.1281C48.8628 82.3611 47.6389 81.1372 46.8719 79.6319C46 77.9206 46 75.6804 46 71.2V60.8C46 56.3196 46 54.0794 46.8719 52.3681C47.6389 50.8628 48.8628 49.6389 50.3681 48.8719C52.0794 48 54.3196 48 58.8 48H67M80 58V46M74 52H86M74 66C74 70.4183 70.4183 74 66 74C61.5817 74 58 70.4183 58 66C58 61.5817 61.5817 58 66 58C70.4183 58 74 61.5817 74 66Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      }

      <img
        class="user-img"
        crossorigin="anonymous"
        width="132"
        height="132"
        *ngIf="!template.img.src && appStateService.user?.image"
        [ngSrc]="(appStateService.user!.image + '?timestamp=' + Date.getTime()) || ''" alt="selfcv_user">
      <!--        [ngSrc]="appStateService.user!.image || ''" alt="selfcv_user">-->


      <img
        class="user-img"
        alt="selfcv_user"
        crossorigin="anonymous"
        width="132"
        height="132"
        *ngIf="template.img.src" [ngSrc]="(template.img!.src + '?timestamp=' + Date.getTime())">
      <!--        *ngIf="template.img.src" [ngSrc]="template.img!.src">-->

    </div>
  </div>

</div>

