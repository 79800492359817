<div class="paid-feature-banner">
  <div class="d-flex ai-c">
    <span [innerHTML]="'upgrade_banner.you_are_using' | translate"></span>
    <i
      class="ph ph-info ml-6"
      [nz-tooltip]="'upgrade_banner.tooltips.to_download' | translate"
    ></i>
  </div>

  <div class="d-flex">

    @if (countryPlan.currency === 'AMD') {
      <button (click)="onUpgrade()" class="mr-10">
        {{ 'upgrade_banner.upgrade_for' | translate }}
        {{ countryPlan.prices[0].totalPrice }} {{ countryPlan.prices[0].currency }}
      </button>
    } @else {
      <button (click)="onUpgrade()" class="mr-10">
        {{ 'upgrade_banner.upgrade_for' | translate }}
        {{ countryPlan.prices[0].currencyCode }} {{ countryPlan.prices[0].totalPrice }}
      </button>
    }

    <button class="icon-bnt" (click)="onSeeMore()" [nz-tooltip]="'upgrade_banner.tooltips.see_more' | translate">
      <i class="ph ph-caret-up"></i>
    </button>
  </div>

</div>

<ng-template #SelectPaidFeaturesRef let-data let-drawerRef="drawerRef">
  <div class="selected-paid-features">
    <ul class="paid-features-list">
      @for (feature of selectedPaidFeatures; track feature.key) {
        @if (feature.state) {
          <li>
            <div class="d-flex al-c jc-sb">
              <div class="feature-title">
                {{ feature.title  | translate }}
                @if (feature.infoMessage) {
                  <i class="ph ph-info ml-6" nz-popover [nzPopoverContent]="feature.infoMessage | translate"></i>
                }
              </div>
              @if (feature.revertible) {
                <button class="sf-btn-bg-none w-100" (click)="feature.action?.()">
                  {{ 'upgrade_banner.revert' | translate }}
                </button>
              }
            </div>
          </li>
        }
      }
    </ul>
  </div>
</ng-template>

<ng-template #PlansDrawerTitleRef>
  <div class="dashboard-sidebar-title">
    {{ 'upgrade_banner.premium_plans' | translate }}
    <i nz-icon nzType="close" nzTheme="outline" (click)="onCloseDrawer()"></i>
  </div>
</ng-template>

<ng-template #FeaturesDrawerTitleRef>
  <div class="dashboard-sidebar-title paid-banner-title">
    <div class="d-flex ai-c">
      {{ 'upgrade_banner.premium_features' | translate }}
      <i class="ph ph-star ml-8" [nz-tooltip]="'upgrade_banner.tooltips.features' | translate"></i>
    </div>

    <div class="d-flex">

      @if (countryPlan.currency === 'AMD') {
        <button (click)="onUpgrade()" class="mr-10">
          {{ 'upgrade_banner.upgrade_for' | translate }}
          {{ countryPlan.prices[0].totalPrice }} {{ countryPlan.prices[0].currency }}
        </button>
      } @else {
        <button (click)="onUpgrade()" class="mr-10">
          {{ 'upgrade_banner.upgrade_for' | translate }}
          {{ countryPlan.prices[0].currencyCode }} {{ countryPlan.prices[0].totalPrice }}
        </button>
      }

      <button class="icon-bnt" (click)="onCloseDrawer()">
        <i class="ph ph-caret-down"></i>
      </button>
    </div>
  </div>
</ng-template>

