import {Subject} from "rxjs";
import {FormsModule} from "@angular/forms";
import {NgOptimizedImage} from "@angular/common";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzPopoverModule} from "ng-zorro-antd/popover";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzSegmentedComponent} from "ng-zorro-antd/segmented";
import {DocumentTypes, TemplateTypes} from "@app/shared/enums";
import {NzMarks, NzSliderComponent} from "ng-zorro-antd/slider";
import {Component, inject, Input, OnDestroy} from '@angular/core';
import {CoverLetter, Template, TemplateConfigs} from "@app/shared/interfaces";
import {AppStateService, TemplateLogicService, TemplateService} from "@app/shared/services";
import {TmLayoutsComponent} from "@app/templates/componenets/tm-layouts/tm-layouts.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'sf-template-settings',
  templateUrl: './template-settings.component.html',
  styleUrls: ['./template-settings.component.scss'],
  imports: [
    FormsModule,
    NzSwitchModule,
    NgOptimizedImage,
    NzDividerModule,
    NzPopoverModule,
    NzToolTipModule,
    TmLayoutsComponent,
    NzSegmentedComponent,
    NzSliderComponent,
    TranslateModule
  ],
  standalone: true
})
export class TemplateSettingsComponent implements OnDestroy {
  private readonly appStateService = inject(AppStateService);
  protected readonly templateService = inject(TemplateService);
  private readonly templateLogicService = inject(TemplateLogicService);

  @Input({required: true}) public template!: Template;

  protected readonly TemplateTypes = TemplateTypes;
  protected readonly DocumentTypes = DocumentTypes;
  private readonly destroy$ = new Subject<void>();

  protected readonly sidebarOptions: ['Left', 'Right'] = ['Left', 'Right'];
  protected readonly marks: NzMarks = {
    306: 'Default',
  };

  constructor() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  protected saveChanges() {
    this.appStateService.saveChanges$.next();
  }

  protected toggleSocialInfoSidebarPosition() {
    this.template.settings.socialInfo.onSidebar = !this.template.settings.socialInfo.onSidebar;
    this.saveChanges();
  }

  protected onToggleSocialInfoTitleState() {
    this.template.settings.socialInfo.title.state = !this.template.settings.socialInfo.title.state;
    this.saveChanges();
  }

  protected onToggleSummaryTitleState() {
    const configs = (this.template.settings as TemplateConfigs);
    configs.summary.title.state = !configs.summary.title.state;
    this.saveChanges();
  }

  protected coverRecipientStateChanged() {
    (this.template.settings as CoverLetter).companyDetails.employer.state = true;
    this.saveChanges();
  }

  protected toggleCoverRecipient() {
    (this.template.settings as CoverLetter).companyDetails.state = !(this.template.settings as CoverLetter).companyDetails.state;
    this.saveChanges();
  }

  protected toggleBranding() {
    this.template.settings!.branding.enabled = !this.template.settings!.branding.enabled;
    this.saveChanges();
    this.appStateService.upgradeBannerState$.next();
  }

  protected toggleCoverSender() {
    (this.template.settings as CoverLetter).content.sender.state = !(this.template.settings as CoverLetter).content.sender.state;
    this.saveChanges();
  }

  protected brandingStateChanged() {
    this.saveChanges();
    this.appStateService.upgradeBannerState$.next();
  }

  protected handleIndexChange(index: number) {
    const settings = this.template.settings as TemplateConfigs;
    const currentIndex = settings.sidebarPosition === 'left' ? 0 : 1;

    if (index !== currentIndex) {
      settings.sidebarPosition = this.sidebarOptions[index]?.toLowerCase() as 'left' | 'right';
      this.saveChanges();
    }
  }

}
