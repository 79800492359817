<div class="icon-picker-container" (sfClickOutside)="onCloseDropdown()">

  <div class="uploader-parent">
    <div class="file" (click)="onOpenDropdown()">
      <i class="{{icon || 'ph ph-plus-circle'}}"></i>
    </div>

    <div class="ph-icons_container" *ngIf="openState">

      <div class="search-part">
        <input type="text" placeholder="Search" [formControl]="searchTerm"/>
        <i class="ph-light ph-magnifying-glass"></i>
      </div>

      <div class="icons-list">
        <i
          class="{{prefix}} {{item.name}}"
          [title]="item.name.replace('ph-', '')"
          [class.active]="(prefix + ' ' + item.name) === icon"
          *ngFor="let item of iconList | appFilter : searchTerm.value"
          (click)="onSelectIcon(item)"
        ></i>
      </div>

    </div>

  </div>

</div>

