<div class="sidebar-top-part-2">
  <div class="tm-top-wrapper tm-top-wrapper-type-5">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        @if (template.img.state) {
          @if (!forPrint || (forPrint && (appStateService.user?.image || template.img.src))) {
            <sf-template-img [stroke]="tmTheme.sidebar.emptyImgColor" [template]="template"/>
          }
        }

        <div class="w-full">
          <sf-tm-name-part
            [insideSidebar]="true"
            [template]="template"
            [tmTheme]="tmTheme"
            [selectedItem]="selectedItem"
            (selectedItemChange)="onSelect($event)"/>

          <sf-tm-summary
            [insideSidebar]="true"
            [template]="template"
            [tmTheme]="tmTheme"
            [selectedItem]="selectedItem"
            (selectedItemChange)="onSelect($event)"
            [hasUnderLine]="false"/>
        </div>
      </div>
    </div>
  </div>
</div>

