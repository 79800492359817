import {DocumentTypes} from "@app/shared/enums";
import {NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {DropdownComponent} from "@app/shared/ui-kits";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {Component, inject, Input, OnInit} from '@angular/core';
import {AppStateService, TemplateLogicService, TemplateService} from "@app/shared/services";
import {
  AllCoverLettersThemeColor,
  AllTemplatesThemeColor,
  Template,
  TemplateConfigs,
  TemplateSettingsFontSize,
  TemplateSettingsThemeColor,
} from "@app/shared/interfaces";
import {projectMessages} from "@app/shared/constants/project-messages";

@Component({
  selector: 'sf-template-design',
  templateUrl: './template-design.component.html',
  styleUrls: ['./template-design.component.scss'],
  imports: [
    NgOptimizedImage,
    NzDividerModule,
    DropdownComponent,
    NzTooltipDirective,
    TranslateModule
  ],
  standalone: true
})
export class TemplateDesignComponent implements OnInit {
  private readonly appStateService = inject(AppStateService);
  protected readonly templateService = inject(TemplateService);
  protected readonly templateLogicService = inject(TemplateLogicService);

  protected readonly fontSizes = this.appStateService.fontSizes;
  protected readonly templateFonts = this.appStateService.templateFonts;
  protected templateColors: TemplateSettingsThemeColor[] = [];
  protected readonly projectMessages = projectMessages;

  @Input({required: true}) public template!: Template;
  @Input({required: true}) public documentType!: DocumentTypes;

  constructor() {
  }

  ngOnInit() {
    const templateId = this.template.templateId || 1;

    switch (this.documentType) {
      case DocumentTypes.CV: {
        this.templateColors = Object.values(this.appStateService.templateThemeColor[('template' + templateId) as keyof AllTemplatesThemeColor]);
        break;
      }
      case DocumentTypes.COVER: {
        this.templateColors = Object.values(this.appStateService.coverLettersThemeColor[('template' + templateId) as keyof AllCoverLettersThemeColor]);
        break;
      }
    }
  }

  public onFontFamilyChanged() {
    this.saveChanges();
    this.appStateService.upgradeBannerState$.next();
  }

  public onChangeFontSizes(size: TemplateSettingsFontSize) {
    this.template.settings.settings.font.size.name = size.name;

    if (this.documentType === DocumentTypes.CV) {
      this.templateLogicService.splitToPages(this.template.settings as TemplateConfigs);
      this.appStateService.setTemplateCurrentFontSize(size.name);
    }

    if (this.documentType === DocumentTypes.COVER) {
      this.appStateService.setCoverCurrentFontSize(size.name);
    }

    this.saveChanges();
  }

  public onSelectTheme(color: TemplateSettingsThemeColor) {
    this.template.settings.settings.theme.colorId = color.id;
    this.appStateService.upgradeBannerState$.next();
    this.appStateService.templateThemeChanged$.next();
    this.saveChanges();
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}

