@if (appStateService.editorBannerState) {
  <div class="editor-banner">
    <div
      (click)="toggleFormatting('bold')"
      (mousedown)="$event.preventDefault()"
      [class.active]="isBoldActive">
      <i class="{{isBoldActive ? 'ph-bold' : 'ph'}} ph-text-b"></i>
    </div>

    <div
      (mousedown)="$event.preventDefault()"
      (click)="toggleFormatting('italic')"
      [class.active]="isItalicActive">
      <i class="{{isItalicActive ? 'ph-bold' : 'ph'}} ph-text-italic"></i>
    </div>

    <div
      (mousedown)="$event.preventDefault()"
      (click)="toggleFormatting('underline')"
      [class.active]="isUnderlineActive">
      <i class="{{isUnderlineActive ? 'ph-bold' : 'ph'}} ph-text-underline"></i>
    </div>

    <div
      (mousedown)="$event.preventDefault()"
      (click)="toggleFormatting('strikethrough')"
      [class.active]="isStrikethroughActive">
      <i class="{{isStrikethroughActive ? 'ph-bold' : 'ph'}} ph-text-strikethrough"></i>
    </div>

    <div
      [nz-tooltip]="'tooltips.add_link' | translate"
      [nzTooltipPlacement]="'bottom'"
      (mousedown)="$event.preventDefault()"
      (click)="insertLink()"
      [class.active]="isStrikethroughActive">
      <i class="{{isLinkSelected() ? 'ph-bold' : 'ph'}} ph-link"></i>
    </div>

    <!-- <div
       nz-tooltip="Clear selections"
       [nzTooltipPlacement]="'bottom'"
       (mousedown)="$event.preventDefault()"
       (click)="clearContent()">
       <i class="ph ph-broom"></i>
     </div>-->
  </div>
}

