import {AppStateService} from "@app/shared/services";
import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {TemplateConfigs, TemplateSettingsThemeColor} from "@app/shared/interfaces";
import {TemplateImgComponent, TmNamePartComponent, TmSummaryComponent} from "@app/templates/componenets";

@Component({
  selector: 'sf-tm-5-sidebar-top-part',
  standalone: true,
  imports: [
    TmNamePartComponent,
    TmSummaryComponent,
    TemplateImgComponent
  ],
  templateUrl: './tm-5-sidebar-top-part.component.html',
  styleUrl: './tm-5-sidebar-top-part.component.scss'
})
export class Tm5SidebarTopPartComponent {
  protected readonly appStateService = inject(AppStateService);
  @Input({required: true}) public template!: TemplateConfigs;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;
  @Input() public forPrint = false;

  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter<any>();

  constructor() {
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
    this.selectedItemChange.emit(this.selectedItem);
  }

}
