import {FormsModule} from "@angular/forms";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzMessageService} from "ng-zorro-antd/message";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {Dimensions, ImageCroppedEvent, ImageCropperComponent, ImageTransform} from "ngx-image-cropper";

export function base64ToFile(base64Image: string): Blob {
  console.log('base64Image', base64Image)
  const split = base64Image.split(',');
  const type = split[0].replace('data:', '').replace(';base64', '');
  const byteString = atob(split[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type});
}

@Component({
  selector: 'sf-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
  imports: [
    ImageCropperComponent,
    FormsModule,
    NzDividerModule,
    NzToolTipModule,
    TranslateModule
  ],
  standalone: true
})
export class ImageCropComponent {
  private readonly translateService = inject(TranslateService);
  private readonly nzMessageService = inject(NzMessageService);

  public imageChangedEvent: Event | null = null;
  public canvasRotation = 0;
  private scale = 1;
  public showCropper = false;
  public transform: ImageTransform = {};
  public imageCroppedEvent: ImageCroppedEvent | null = null;
  @Input() public loadingState = false;
  @Output() save$ = new EventEmitter<ImageCroppedEvent>();

  public imageCropped(event: ImageCroppedEvent): void {
    console.log(event);
    this.imageCroppedEvent = event;
  }

  public imageLoaded(): void {
    this.showCropper = true;
  }

  public cropperReady(sourceImageDimensions: Dimensions): void {
    console.log('Cropper ready', sourceImageDimensions);
  }

  public loadImageFailed() {
    this.nzMessageService.error(
      this.translateService.instant('project_messages.image_load_failed')
    );
  }

  public rotateLeft(): void {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  public rotateRight(): void {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  public flipHorizontal(): void {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  public flipVertical(): void {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  public resetImage(): void {
    this.scale = 1;
    this.canvasRotation = 0;
    this.transform = {};
  }

  public zoomOut(): void {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  public zoomIn(): void {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  public onImgSave(): void {
    this.save$.emit(this.imageCroppedEvent!);
  }

}
