<div class="tm-st-banner" [class.pure-template]="template.pureTemplate">

  <div class="new-entry" (click)="onAddListItem($event, section, listIndex, ListSectionRef)">
    <i class="ph ph-plus-circle"></i>
    New Entry
  </div>

  <i
    class="ph ph-caret-down"
    [class.disabled]="last"
    (click)="onListMoveDown(section, listIndex, ListSectionRef)"
  ></i>

  <i
    class="ph ph-caret-up"
    [class.disabled]="first"
    (click)="onListMoveUp(section, listIndex, ListSectionRef)"
  ></i>

  <!--  <i class="ph ph-lightbulb-filament"></i>-->

  <i
    class="ph ph-gear"
    [class.active]="listSectionSettingsSelected"
    (click)="onSelectListSectionSettings($event)"
  ></i>

  @if (section.items.length > 1) {
    <i
      class="ph ph-trash-simple"
      (click)="onRemoveListSectionItem(section, listIndex, ListSectionRef)"
    ></i>
  }

  @if (listSectionSettingsSelected) {
    <div
      class="tm-st-banner-settings list-type"
      [sfCapture]="true"
      (sfClickOutside)="listSectionSettingsSelected = false">

      @if (template.pureTemplate) {
        <i
          class="ph ph-question actions-st-info-icon"
          nz-popover
          [nzPopoverContent]="PureTemplateRef"
        ></i>
      }

      <nz-radio-group
        [(ngModel)]="section.settings!.selectedShape.type"
        (ngModelChange)="onChangedShapeType($event, section)">

        <div class="text-st-wr rd-wr">
          <label nz-radio nzValue="text">Text</label>
        </div>

        @if (section.type === TemplateSectionTypes.LANGUAGES) {
          <div class="text-st-wr rd-wr">
            <label nz-radio nzValue="textOptions">Text Options</label>

            @if (section.settings!.selectedShape.type === TemplateSettingsShapes.TEXT_OPTIONS) {
              <div class="st-text-options">
                <ul>
                  <li
                    *ngFor="let text of templateSectionsShape.text.items"
                    [class.active]="text.name === sectionItem.textValue"
                    (click)="onSelectInfTextOption(sectionItem, text)">
                    {{ text.name }}

                    <i class="ph ph-check"></i>
                  </li>
                </ul>
              </div>
            }
          </div>
        }

        <div class="infographics-st-wr rd-wr">
          <label nz-radio nzValue="infographics">Infographics</label>

          @if (section.settings!.selectedShape.type === TemplateSettingsShapes.INFOGRAPHICS) {
            <div class="st-inf-options">
              @for (inf of templateSectionsShape.infographics.items; track inf.type) {
                <div class="round-inf">
                  <div [class.premium-inf]="inf.paid" class="{{inf.type}}">

                    @if (inf.paid) {
                      <i
                        class="ph ph-star"
                        [nz-tooltip]="'tooltips.paid_feature' | translate"
                        [nzTooltipMouseEnterDelay]="1"
                      ></i>
                    }

                    <div
                      class="lng-rounds {{inf.type}}"
                      [class.active]="section.settings!.selectedShape.infographicValue === inf.type"
                      (click)="onSelectInfographics(section, inf)">
                      @for (count of [1, 2, 3, 4, 5]; track count) {
                        <div [class.active]="count < 3"></div>
                      }

                      <i class="ph ph-check"></i>
                    </div>


                  </div>
                </div>
              }
            </div>
          }
        </div>
      </nz-radio-group>

      <div class="chip-st-wr rd-wr">
        <div class="checkbox-part">
          <div class="sf-checkbox">
            <input
              #TagInputRef
              [checked]="!!section.settings!.selectedShape.chipValue"
              type="checkbox" id="tm-chip"
              (change)="onChangeTagCheckbox($event)">
            <label for="tm-chip">Chips</label>
          </div>
        </div>

        @if (TagInputRef.checked || section.settings!.selectedShape.chipValue) {
          <div class="st-chip-options">
            @for (chip of templateSectionsShape.chips.items; track chip.type) {
              <div
                class="chip-item-parent"
                [class.active]="section.settings?.selectedShape!.chipValue === chip.type"
                (click)="onSelectChip(section, chip)">
                <div class="chip-item {{chip.className}}">
                  Some Text
                </div>

                <i class="ph ph-check"></i>
              </div>
            }
          </div>
        }
      </div>

      <div class="checkbox-part">
        <div class="sf-checkbox">
          <input
            type="checkbox" id="tm-text"
            [(ngModel)]="section.settings!.selectedShape.hasIcon"
            (ngModelChange)="saveChanges()">
          <label for="tm-text">Has Icon</label>
        </div>

        @if (section.settings!.selectedShape.hasIcon) {
          <div class="mt-10">
            <app-icon-picker
              [(icon)]="$any(sectionItem).icon"
              (iconChange)="saveChanges()"
              [iconList]="$any(appStateService.phosphorIcons)"/>
          </div>
        }
      </div>

      @if (sectionName === SectionNames.SIDEBAR_SECTION && section.settings?.selectedShape!.infographicValue === '') {
        <div class="list-vs-wrap">
          <i
            class="ph ph-list mr-10"
            [nz-tooltip]="'tooltips.list' | translate"
            [nzTooltipMouseEnterDelay]="1"
            [class.active]="section.settings!.listTypeOnSidebar === 'list'"
            (click)="changeListTOGridOrBack(section, 'list')"
          ></i>

          <i
            class="ph ph-squares-four mr-10"
            [nz-tooltip]="'tooltips.wrap' | translate"
            [nzTooltipMouseEnterDelay]="1"
            [class.active]="section.settings!.listTypeOnSidebar === 'wrap'"
            (click)="changeListTOGridOrBack(section, 'wrap')"
          ></i>

          @if (section.settings!.selectedShape.type === TemplateSettingsShapes.TEXT_OPTIONS) {
            <i
              class="ph ph-grid-four"
              [nz-tooltip]="'tooltips.grid' | translate"
              [nzTooltipMouseEnterDelay]="1"
              [class.active]="section.settings!.listTypeOnSidebar === 'grid'"
              (click)="changeListTOGridOrBack(section, 'grid')"
            ></i>
          }
        </div>
      }

    </div>
  }


</div>

<ng-template #PureTemplateRef>
  <div [innerHTML]="projectMessages.pureTmActions | translate"></div>
</ng-template>
