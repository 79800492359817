<div
  class="lng-rounds {{section.settings?.selectedShape!.infographicValue}}"
  [style.--infBgColor]="colors.bgColor"
  [style.--infActiveBgColor]="colors.activeBgColor"
  [style.--borderColor]="colors.borderColor">
  <div
    *ngFor="let count of [1,2,3,4,5]"
    [class.active]="(sectionItem.skillCount! >= count) && (hoveredRound === 0)"
    [class.hovered]="hoveredRound >= count"
    (click)="onSelect(count)"
    (mouseenter)="setHoveredRound(count)"
    (mouseleave)="clearHoveredRound()"
  ></div>
</div>
