/*!
 * Intro.js v8.0.0-beta.1
 * https://introjs.com
 *
 * Copyright (C) 2012-2024 Afshin Mehrabani (@afshinmeh).
 * https://introjs.com
 *
 * Date: Sun, 21 Jul 2024 11:55:52 GMT
 */
var t = function (e, n) {
  return t = Object.setPrototypeOf || {
    __proto__: []
  } instanceof Array && function (t, e) {
    t.__proto__ = e;
  } || function (t, e) {
    for (var n in e) Object.prototype.hasOwnProperty.call(e, n) && (t[n] = e[n]);
  }, t(e, n);
};
function e(t, e, n, o) {
  return new (n || (n = Promise))(function (i, r) {
    function s(t) {
      try {
        l(o.next(t));
      } catch (t) {
        r(t);
      }
    }
    function a(t) {
      try {
        l(o.throw(t));
      } catch (t) {
        r(t);
      }
    }
    function l(t) {
      var e;
      t.done ? i(t.value) : (e = t.value, e instanceof n ? e : new n(function (t) {
        t(e);
      })).then(s, a);
    }
    l((o = o.apply(t, e || [])).next());
  });
}
function n(t, e) {
  var n,
    o,
    i,
    r,
    s = {
      label: 0,
      sent: function () {
        if (1 & i[0]) throw i[1];
        return i[1];
      },
      trys: [],
      ops: []
    };
  return r = {
    next: a(0),
    throw: a(1),
    return: a(2)
  }, "function" == typeof Symbol && (r[Symbol.iterator] = function () {
    return this;
  }), r;
  function a(a) {
    return function (l) {
      return function (a) {
        if (n) throw new TypeError("Generator is already executing.");
        for (; r && (r = 0, a[0] && (s = 0)), s;) try {
          if (n = 1, o && (i = 2 & a[0] ? o.return : a[0] ? o.throw || ((i = o.return) && i.call(o), 0) : o.next) && !(i = i.call(o, a[1])).done) return i;
          switch (o = 0, i && (a = [2 & a[0], i.value]), a[0]) {
            case 0:
            case 1:
              i = a;
              break;
            case 4:
              return s.label++, {
                value: a[1],
                done: !1
              };
            case 5:
              s.label++, o = a[1], a = [0];
              continue;
            case 7:
              a = s.ops.pop(), s.trys.pop();
              continue;
            default:
              if (!(i = s.trys, (i = i.length > 0 && i[i.length - 1]) || 6 !== a[0] && 2 !== a[0])) {
                s = 0;
                continue;
              }
              if (3 === a[0] && (!i || a[1] > i[0] && a[1] < i[3])) {
                s.label = a[1];
                break;
              }
              if (6 === a[0] && s.label < i[1]) {
                s.label = i[1], i = a;
                break;
              }
              if (i && s.label < i[2]) {
                s.label = i[2], s.ops.push(a);
                break;
              }
              i[2] && s.ops.pop(), s.trys.pop();
              continue;
          }
          a = e.call(t, s);
        } catch (t) {
          a = [6, t], o = 0;
        } finally {
          n = i = 0;
        }
        if (5 & a[0]) throw a[1];
        return {
          value: a[0] ? a[1] : void 0,
          done: !0
        };
      }([a, l]);
    };
  }
}
"function" == typeof SuppressedError && SuppressedError;
function o(t) {
  return o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
    return typeof t;
  } : function (t) {
    return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
  }, o(t);
}
function i(t) {
  if (null === t || "object" !== o(t) || "nodeType" in t) return t;
  var e = {};
  for (var n in t) "jQuery" in window && window.jQuery && t[n] instanceof window.jQuery ? e[n] = t[n] : e[n] = i(t[n]);
  return e;
}
var r = function (t, e) {
    return (null != e ? e : document).querySelector(t);
  },
  s = function (t, e) {
    return (null != e ? e : document).querySelectorAll(t);
  },
  a = function (t, e) {
    return r(".".concat(t), e);
  },
  l = function (t, e) {
    return s(".".concat(t), e);
  },
  c = function (t, e) {
    var n = a(t, e);
    if (!n) throw new Error("Element with class name ".concat(t, " not found"));
    return n;
  };
function u(t, e, n) {
  return t[e] = n, t;
}
function p(t, e) {
  for (var n = 0, o = Object.entries(e); n < o.length; n++) {
    var i = o[n];
    t = u(t, i[0], i[1]);
  }
  return t;
}
var h = function (t) {
  return "function" == typeof t;
};
function d(t, e) {
  var n = "";
  return "currentStyle" in t ? n = t.currentStyle[e] : document.defaultView && document.defaultView.getComputedStyle && (n = document.defaultView.getComputedStyle(t, null).getPropertyValue(e)), n && n.toLowerCase ? n.toLowerCase() : n;
}
function f(t) {
  var e = t.parentElement;
  return !(!e || "HTML" === e.nodeName) && ("fixed" === d(t, "position") || f(e));
}
function g(t, e) {
  var n = document.body,
    o = document.documentElement,
    i = window.pageYOffset || o.scrollTop || n.scrollTop,
    r = window.pageXOffset || o.scrollLeft || n.scrollLeft;
  e = e || n;
  var s = t.getBoundingClientRect(),
    a = e.getBoundingClientRect(),
    l = d(e, "position"),
    c = {
      width: s.width,
      height: s.height
    };
  return "body" !== e.tagName.toLowerCase() && "relative" === l || "sticky" === l ? Object.assign(c, {
    top: s.top - a.top,
    left: s.left - a.left
  }) : f(t) ? Object.assign(c, {
    top: s.top,
    left: s.left
  }) : Object.assign(c, {
    top: s.top + i,
    left: s.left + r
  });
}
var v = function (t, e, n) {
  if (void 0 !== n) {
    var o = g(n),
      i = 20,
      r = 20;
    switch (t) {
      default:
      case "top-left":
        e.style.left = "".concat(o.left, "px"), e.style.top = "".concat(o.top, "px");
        break;
      case "top-right":
        e.style.left = "".concat(o.left + o.width - i, "px"), e.style.top = "".concat(o.top, "px");
        break;
      case "bottom-left":
        e.style.left = "".concat(o.left, "px"), e.style.top = "".concat(o.top + o.height - r, "px");
        break;
      case "bottom-right":
        e.style.left = "".concat(o.left + o.width - i, "px"), e.style.top = "".concat(o.top + o.height - r, "px");
        break;
      case "middle-left":
        e.style.left = "".concat(o.left, "px"), e.style.top = "".concat(o.top + (o.height - r) / 2, "px");
        break;
      case "middle-right":
        e.style.left = "".concat(o.left + o.width - i, "px"), e.style.top = "".concat(o.top + (o.height - r) / 2, "px");
        break;
      case "middle-middle":
        e.style.left = "".concat(o.left + (o.width - i) / 2, "px"), e.style.top = "".concat(o.top + (o.height - r) / 2, "px");
        break;
      case "bottom-middle":
        e.style.left = "".concat(o.left + (o.width - i) / 2, "px"), e.style.top = "".concat(o.top + o.height - r, "px");
        break;
      case "top-middle":
        e.style.left = "".concat(o.left + (o.width - i) / 2, "px"), e.style.top = "".concat(o.top, "px");
    }
  }
};
var b = new (function () {
    function t() {}
    return t.prototype.on = function (t, e, n, o) {
      "addEventListener" in t ? t.addEventListener(e, n, o) : "attachEvent" in t && t.attachEvent("on".concat(e), n);
    }, t.prototype.off = function (t, e, n, o) {
      "removeEventListener" in t ? t.removeEventListener(e, n, o) : "detachEvent" in t && t.detachEvent("on".concat(e), n);
    }, t;
  }())(),
  m = function (t) {
    return t ? "string" == typeof t ? function (t, e) {
      var n = r(t, e);
      if (!n) throw new Error("Element with selector ".concat(t, " not found"));
      return n;
    }(t) : t : document.body;
  };
function y(t, e) {
  var n = "";
  if (t.style.cssText && (n += t.style.cssText), "string" == typeof e) n += e;else for (var o in e) n += "".concat(o, ":").concat(e[o], ";");
  t.style.cssText = n;
}
function w(t, e) {
  var n = document.createElement(t);
  e = e || {};
  var o = /^(?:role|data-|aria-)/;
  for (var i in e) {
    var r = e[i];
    "style" === i && "function" != typeof r ? y(n, r) : "string" == typeof r && i.match(o) ? n.setAttribute(i, r) : n[i] = r;
  }
  return n;
}
function C(t) {
  t.setAttribute("role", "button"), t.tabIndex = 0;
}
var S = function (t) {
    for (var e = [], n = 1; n < arguments.length; n++) e[n - 1] = arguments[n];
    for (var o = 0, i = e; o < i.length; o++) {
      var r = i[o];
      if (t instanceof SVGElement) {
        var s = t.getAttribute("class") || "";
        s.match(r) || O(t, s, r);
      } else void 0 !== t.classList ? t.classList.add(r) : t.className.match(r) || O(t, t.className, r);
    }
  },
  O = function (t) {
    for (var e = [], n = 1; n < arguments.length; n++) e[n - 1] = arguments[n];
    var o = e.filter(Boolean).join(" ");
    t instanceof SVGElement ? t.setAttribute("class", o) : void 0 !== t.classList ? t.classList.value = o : t.className = o;
  },
  j = function (t, e) {
    if (t instanceof SVGElement) {
      var n = t.getAttribute("class") || "";
      t.setAttribute("class", n.replace(e, "").replace(/\s\s+/g, " ").trim());
    } else t.className = t.className.replace(e, "").replace(/\s\s+/g, " ").trim();
  },
  k = function () {
    return a("introjs-hints");
  },
  x = function () {
    return l("introjs-hint", k());
  },
  A = function (t) {
    return l("".concat("introjs-hint", "[").concat("data-step", '="').concat(t, '"]'), k())[0];
  };
function E(t, o) {
  var i;
  return e(this, void 0, void 0, function () {
    var e;
    return n(this, function (n) {
      return e = A(o), M(), e && S(e, "introjs-hidehint"), null === (i = t.callback("hintClose")) || void 0 === i || i.call(t, o), [2];
    });
  });
}
function T(t) {
  return e(this, void 0, void 0, function () {
    var e, o, i, r, s;
    return n(this, function (n) {
      switch (n.label) {
        case 0:
          e = x(), o = 0, i = Array.from(e), n.label = 1;
        case 1:
          return o < i.length ? (r = i[o], (s = r.getAttribute("data-step")) ? [4, E(t, parseInt(s, 10))] : [3, 3]) : [3, 4];
        case 2:
          n.sent(), n.label = 3;
        case 3:
          return o++, [3, 1];
        case 4:
          return [2];
      }
    });
  });
}
var _ = function (t, e, n, o) {
  if (e && t && n) {
    n instanceof Element && f(n) ? S(e, "introjs-fixedTooltip") : j(e, "introjs-fixedTooltip");
    var i = g(n, t);
    y(e, {
      width: "".concat(i.width + o, "px"),
      height: "".concat(i.height + o, "px"),
      top: "".concat(i.top - o / 2, "px"),
      left: "".concat(i.left - o / 2, "px")
    });
  }
};
function H() {
  if (void 0 !== window.innerWidth) return {
    width: window.innerWidth,
    height: window.innerHeight
  };
  var t = document.documentElement;
  return {
    width: t.clientWidth,
    height: t.clientHeight
  };
}
function N(t, e, n, o, i) {
  return t.left + e + n.width > o.width ? (i.style.left = "".concat(o.width - n.width - t.left, "px"), !1) : (i.style.left = "".concat(e, "px"), !0);
}
function L(t, e, n, o) {
  return t.left + t.width - e - n.width < 0 ? (o.style.left = "".concat(-t.left, "px"), !1) : (o.style.right = "".concat(e, "px"), !0);
}
function P(t, e) {
  t.includes(e) && t.splice(t.indexOf(e), 1);
}
function R(t, e, n, o) {
  var i = t.slice(),
    r = H(),
    s = g(n).height + 10,
    a = g(n).width + 20,
    l = e.getBoundingClientRect(),
    c = "floating";
  if (l.bottom + s > r.height && P(i, "bottom"), l.top - s < 0 && P(i, "top"), l.right + a > r.width && P(i, "right"), l.left - a < 0 && P(i, "left"), o && (o = o.split("-")[0]), i.length && (c = i[0], i.includes(o) && (c = o)), "top" === c || "bottom" === c) {
    var u = void 0,
      p = [];
    "top" === c ? (u = "top-middle-aligned", p = ["top-left-aligned", "top-middle-aligned", "top-right-aligned"]) : (u = "bottom-middle-aligned", p = ["bottom-left-aligned", "bottom-middle-aligned", "bottom-right-aligned"]), c = function (t, e, n, o) {
      var i = e / 2,
        r = Math.min(n, window.screen.width);
      return r - t < e && (P(o, "top-left-aligned"), P(o, "bottom-left-aligned")), (t < i || r - t < i) && (P(o, "top-middle-aligned"), P(o, "bottom-middle-aligned")), t < e && (P(o, "top-right-aligned"), P(o, "bottom-right-aligned")), o.length ? o[0] : null;
    }(l.left, a, r.width, p) || u;
  }
  return c;
}
var B,
  I = function (t, e, n, o, i, r, s, a, l) {
    var c, u, p;
    void 0 === r && (r = !1), void 0 === s && (s = !0), void 0 === a && (a = ""), void 0 === l && (l = !1), t.style.top = "", t.style.right = "", t.style.bottom = "", t.style.left = "", t.style.marginLeft = "", t.style.marginTop = "", e.style.display = "inherit", O(t, "introjs-tooltip", a), t.setAttribute("role", "dialog"), "floating" !== o && s && (o = R(i, n, t, o)), u = g(n), c = g(t), p = H(), S(t, "introjs-".concat(o));
    var h = u.width / 2 - c.width / 2;
    switch (o) {
      case "top-right-aligned":
        O(e, "introjs-arrow bottom-right");
        var d = 0;
        L(u, d, c, t), t.style.bottom = "".concat(u.height + 20, "px");
        break;
      case "top-middle-aligned":
        O(e, "introjs-arrow bottom-middle"), l && (h += 5), L(u, h, c, t) && (t.style.right = "", N(u, h, c, p, t)), t.style.bottom = "".concat(u.height + 20, "px");
        break;
      case "top-left-aligned":
      case "top":
        O(e, "introjs-arrow bottom"), N(u, l ? 0 : 15, c, p, t), t.style.bottom = "".concat(u.height + 20, "px");
        break;
      case "right":
        t.style.left = "".concat(u.width + 20, "px"), u.top + c.height > p.height ? (O(e, "introjs-arrow left-bottom"), t.style.top = "-".concat(c.height - u.height - 20, "px")) : O(e, "introjs-arrow left");
        break;
      case "left":
        l || !0 !== r || (t.style.top = "15px"), u.top + c.height > p.height ? (t.style.top = "-".concat(c.height - u.height - 20, "px"), O(e, "introjs-arrow right-bottom")) : O(e, "introjs-arrow right"), t.style.right = "".concat(u.width + 20, "px");
        break;
      case "floating":
        e.style.display = "none", t.style.left = "50%", t.style.top = "50%", t.style.marginLeft = "-".concat(c.width / 2, "px"), t.style.marginTop = "-".concat(c.height / 2, "px");
        break;
      case "bottom-right-aligned":
        O(e, "introjs-arrow top-right"), L(u, d = 0, c, t), t.style.top = "".concat(u.height + 20, "px");
        break;
      case "bottom-middle-aligned":
        O(e, "introjs-arrow top-middle"), l && (h += 5), L(u, h, c, t) && (t.style.right = "", N(u, h, c, p, t)), t.style.top = "".concat(u.height + 20, "px");
        break;
      default:
        O(e, "introjs-arrow top"), N(u, 0, c, p, t), t.style.top = "".concat(u.height + 20, "px");
    }
  };
function M() {
  var t = a("introjs-hintReference");
  if (t && t.parentNode) {
    var e = t.getAttribute("data-step");
    if (!e) return;
    return t.parentNode.removeChild(t), e;
  }
}
function D(t, o) {
  var i, s;
  return e(this, void 0, void 0, function () {
    var e, a, l, c, u, p, h, d, f, g, v, m;
    return n(this, function (n) {
      switch (n.label) {
        case 0:
          return e = r(".".concat("introjs-hint", "[").concat("data-step", '="').concat(o, '"]')), a = t.getHint(o), e && a ? [4, null === (i = t.callback("hintClick")) || void 0 === i ? void 0 : i.call(t, e, a, o)] : [2];
        case 1:
          return n.sent(), void 0 !== (l = M()) && parseInt(l, 10) === o ? [2] : (c = w("div", {
            className: "introjs-tooltip"
          }), u = w("div"), p = w("div"), h = w("div"), c.onclick = function (t) {
            t.stopPropagation ? t.stopPropagation() : t.cancelBubble = !0;
          }, O(u, "introjs-tooltiptext"), (d = w("p")).innerHTML = a.hint || "", u.appendChild(d), t.getOption("hintShowButton") && ((f = w("a")).className = t.getOption("buttonClass"), f.setAttribute("role", "button"), f.innerHTML = t.getOption("hintButtonLabel"), f.onclick = function () {
            return E(t, o);
          }, u.appendChild(f)), O(p, "introjs-arrow"), c.appendChild(p), c.appendChild(u), g = e.getAttribute("data-step") || "", (v = t.getHint(parseInt(g, 10))) ? (O(h, "introjs-tooltipReferenceLayer", "introjs-hintReference"), h.setAttribute("data-step", g), m = t.getOption("helperElementPadding"), _(t.getTargetElement(), h, v.element, m), h.appendChild(c), document.body.appendChild(h), I(c, p, v.element, v.position, t.getOption("positionPrecedence"), !1, t.getOption("autoPosition"), null !== (s = v.tooltipClass) && void 0 !== s ? s : t.getOption("tooltipClass")), B = function () {
            M(), b.off(document, "click", B, !1);
          }, b.on(document, "click", B, !1), [2]) : [2]);
      }
    });
  });
}
var z = function (t, e) {
  return function (n) {
    var o = n || window.event;
    o && o.stopPropagation && o.stopPropagation(), o && null !== o.cancelBubble && (o.cancelBubble = !0), D(t, e);
  };
};
function F(t) {
  var o;
  return e(this, void 0, void 0, function () {
    var e, i, s, l, c, u, p;
    return n(this, function (n) {
      for (null === (e = a("introjs-hints")) && (e = w("div", {
        className: "introjs-hints"
      })), i = t.getHints(), s = 0; s < i.length; s++) {
        if (l = i[s], r(".".concat("introjs-hint", "[").concat("data-step", '="').concat(s, '"]'))) return [2];
        C(c = w("a", {
          className: "introjs-hint"
        })), c.onclick = z(t, s), l.hintAnimation || S(c, "introjs-hint-no-anim"), f(l.element) && S(c, "introjs-fixedhint"), u = w("div", {
          className: "introjs-hint-dot"
        }), p = w("div", {
          className: "introjs-hint-pulse"
        }), c.appendChild(u), c.appendChild(p), c.setAttribute("data-step", s.toString()), l.hintTargetElement = l.element, l.element = c, v(l.hintPosition, c, l.hintTargetElement), e.appendChild(c);
      }
      return document.body.appendChild(e), null === (o = t.callback("hintsAdded")) || void 0 === o || o.call(t), t.enableHintAutoRefresh(), [2];
    });
  });
}
function J(t) {
  return e(this, void 0, void 0, function () {
    var e, o, i, r, s;
    return n(this, function (n) {
      switch (n.label) {
        case 0:
          if (!(null == (e = x()) ? void 0 : e.length)) return [3, 1];
          for (o = 0, i = Array.from(e); o < i.length; o++) r = i[o], (s = r.getAttribute("data-step")) && V(parseInt(s, 10));
          return [3, 3];
        case 1:
          return [4, t.render()];
        case 2:
          n.sent(), n.label = 3;
        case 3:
          return [2];
      }
    });
  });
}
function V(t) {
  var e = A(t);
  e && j(e, new RegExp("introjs-hidehint", "g"));
}
function G(t) {
  var e = A(t);
  e && e.parentNode && e.parentNode.removeChild(e);
}
var q = function () {
  function t(t, e) {
    this._hints = [], this.callbacks = {}, this._targetElement = m(t), this._options = e ? p(this._options, e) : {
      hints: [],
      isActive: !0,
      tooltipPosition: "bottom",
      tooltipClass: "",
      hintPosition: "top-middle",
      hintButtonLabel: "Got it",
      hintShowButton: !0,
      hintAutoRefreshInterval: 10,
      hintAnimation: !0,
      buttonClass: "introjs-button",
      helperElementPadding: 10,
      autoPosition: !0,
      positionPrecedence: ["bottom", "top", "right", "left"]
    };
  }
  return t.prototype.callback = function (t) {
    var e = this.callbacks[t];
    if (h(e)) return e;
  }, t.prototype.getTargetElement = function () {
    return this._targetElement;
  }, t.prototype.getHints = function () {
    return this._hints;
  }, t.prototype.getHint = function (t) {
    return this._hints[t];
  }, t.prototype.setHints = function (t) {
    return this._hints = t, this;
  }, t.prototype.addHint = function (t) {
    return this._hints.push(t), this;
  }, t.prototype.render = function () {
    return e(this, void 0, void 0, function () {
      return n(this, function (t) {
        switch (t.label) {
          case 0:
            return this.isActive() ? (function (t) {
              t.setHints([]);
              var e = t.getTargetElement(),
                n = t.getOption("hints");
              if (n && n.length > 0) for (var o = 0, a = n; o < a.length; o++) {
                var l = i(a[o]);
                "string" == typeof l.element && (l.element = r(l.element)), l.hintPosition = l.hintPosition || t.getOption("hintPosition"), l.hintAnimation = l.hintAnimation || t.getOption("hintAnimation"), null !== l.element && t.addHint(l);
              } else {
                var c = Array.from(s("*[".concat("data-hint", "]"), e));
                if (!c || !c.length) return !1;
                for (var u = 0, p = c; u < p.length; u++) {
                  var h = p[u],
                    d = h.getAttribute("data-hint-position"),
                    f = t.getOption("hintAnimation");
                  d && (f = "true" === d), t.addHint({
                    element: h,
                    hint: h.getAttribute("data-hint") || "",
                    hintPosition: h.getAttribute("data-hint-position") || t.getOption("hintPosition"),
                    hintAnimation: f,
                    tooltipClass: h.getAttribute("data-tooltip-class") || void 0,
                    position: h.getAttribute("data-position") || t.getOption("tooltipPosition")
                  });
                }
              }
            }(this), [4, F(this)]) : [2, this];
          case 1:
            return t.sent(), [2, this];
        }
      });
    });
  }, t.prototype.addHints = function () {
    return e(this, void 0, void 0, function () {
      return n(this, function (t) {
        return [2, this.render()];
      });
    });
  }, t.prototype.hideHint = function (t) {
    return e(this, void 0, void 0, function () {
      return n(this, function (e) {
        switch (e.label) {
          case 0:
            return [4, E(this, t)];
          case 1:
            return e.sent(), [2, this];
        }
      });
    });
  }, t.prototype.hideHints = function () {
    return e(this, void 0, void 0, function () {
      return n(this, function (t) {
        switch (t.label) {
          case 0:
            return [4, T(this)];
          case 1:
            return t.sent(), [2, this];
        }
      });
    });
  }, t.prototype.showHint = function (t) {
    return V(t), this;
  }, t.prototype.showHints = function () {
    return e(this, void 0, void 0, function () {
      return n(this, function (t) {
        switch (t.label) {
          case 0:
            return [4, J(this)];
          case 1:
            return t.sent(), [2, this];
        }
      });
    });
  }, t.prototype.destroy = function () {
    return function (t) {
      for (var e = x(), n = 0, o = Array.from(e); n < o.length; n++) {
        var i = o[n].getAttribute("data-step");
        null !== i && G(parseInt(i, 10));
      }
      t.disableHintAutoRefresh();
    }(this), this;
  }, t.prototype.removeHints = function () {
    return this.destroy(), this;
  }, t.prototype.removeHint = function (t) {
    return G(t), this;
  }, t.prototype.showHintDialog = function (t) {
    return e(this, void 0, void 0, function () {
      return n(this, function (e) {
        switch (e.label) {
          case 0:
            return [4, D(this, t)];
          case 1:
            return e.sent(), [2, this];
        }
      });
    });
  }, t.prototype.enableHintAutoRefresh = function () {
    var t,
      e,
      n,
      o = this,
      i = this.getOption("hintAutoRefreshInterval");
    return i >= 0 && (this._hintsAutoRefreshFunction = (t = function () {
      return function (t) {
        for (var e = 0, n = t.getHints(); e < n.length; e++) {
          var o = n[e],
            i = o.hintTargetElement,
            r = o.hintPosition,
            s = o.element;
          v(r, s, i);
        }
      }(o);
    }, e = i, function () {
      for (var o = [], i = 0; i < arguments.length; i++) o[i] = arguments[i];
      window.clearTimeout(n), n = window.setTimeout(function () {
        t(o);
      }, e);
    }), b.on(window, "scroll", this._hintsAutoRefreshFunction, !0), b.on(window, "resize", this._hintsAutoRefreshFunction, !0)), this;
  }, t.prototype.disableHintAutoRefresh = function () {
    return this._hintsAutoRefreshFunction && (b.off(window, "scroll", this._hintsAutoRefreshFunction, !0), b.on(window, "resize", this._hintsAutoRefreshFunction, !0), this._hintsAutoRefreshFunction = void 0), this;
  }, t.prototype.getOption = function (t) {
    return this._options[t];
  }, t.prototype.setOptions = function (t) {
    return this._options = p(this._options, t), this;
  }, t.prototype.setOption = function (t, e) {
    return this._options = u(this._options, t, e), this;
  }, t.prototype.clone = function () {
    return new t(this._targetElement, this._options);
  }, t.prototype.isActive = function () {
    return this.getOption("isActive");
  }, t.prototype.onHintsAdded = function (t) {
    if (!h(t)) throw new Error("Provided callback for onhintsadded was not a function.");
    return this.callbacks.hintsAdded = t, this;
  }, t.prototype.onhintsadded = function (t) {
    this.onHintsAdded(t);
  }, t.prototype.onHintClick = function (t) {
    if (!h(t)) throw new Error("Provided callback for onhintclick was not a function.");
    return this.callbacks.hintClick = t, this;
  }, t.prototype.onhintclick = function (t) {
    this.onHintClick(t);
  }, t.prototype.onHintClose = function (t) {
    if (!h(t)) throw new Error("Provided callback for onhintclose was not a function.");
    return this.callbacks.hintClose = t, this;
  }, t.prototype.onhintclose = function (t) {
    this.onHintClose(t);
  }, t;
}();
function K(t, e) {
  if (t) {
    var n = function (t) {
      var e = window.getComputedStyle(t),
        n = "absolute" === e.position,
        o = /(auto|scroll)/;
      if ("fixed" === e.position) return document.body;
      for (var i = t; i = i.parentElement;) if (e = window.getComputedStyle(i), (!n || "static" !== e.position) && o.test(e.overflow + e.overflowY + e.overflowX)) return i;
      return document.body;
    }(e);
    n !== document.body && (n.scrollTop = e.offsetTop - n.offsetTop);
  }
}
function W(t, e, n, o, i) {
  var r;
  if ("off" !== e && t && (r = "tooltip" === e ? i.getBoundingClientRect() : o.getBoundingClientRect(), !function (t) {
    var e = t.getBoundingClientRect();
    return e.top >= 0 && e.left >= 0 && e.bottom + 80 <= window.innerHeight && e.right <= window.innerWidth;
  }(o))) {
    var s = H().height;
    r.bottom - (r.bottom - r.top) < 0 || o.clientHeight > s ? window.scrollBy(0, r.top - (s / 2 - r.height / 2) - n) : window.scrollBy(0, r.top - (s / 2 - r.height / 2) + n);
  }
}
function Q() {
  for (var t = 0, e = Array.from(l("introjs-showElement")); t < e.length; t++) {
    var n = e[t];
    j(n, /introjs-[a-zA-Z]+/g);
  }
}
function X(t, e, n) {
  if (void 0 === n && (n = !1), n) {
    var o = e.style.opacity || "1";
    y(e, {
      opacity: "0"
    }), window.setTimeout(function () {
      y(e, {
        opacity: o
      });
    }, 10);
  }
  t.appendChild(e);
}
var Y,
  U = function (t, e, n, o) {
    _(t, e, n.element, "floating" === n.position ? 0 : o);
  },
  Z = function (t, e) {
    return (t + 1) / e * 100;
  };
function $(t, e) {
  var n = w("div", {
    className: "introjs-bullets"
  });
  !1 === t.getOption("showBullets") && (n.style.display = "none");
  var o = w("ul");
  o.setAttribute("role", "tablist");
  for (var i = function () {
      var e = this.getAttribute("data-step-number");
      null != e && t.goToStep(parseInt(e, 10));
    }, r = t.getSteps(), s = 0; s < r.length; s++) {
    var a = r[s].step,
      l = w("li"),
      c = w("a");
    l.setAttribute("role", "presentation"), c.setAttribute("role", "tab"), c.onclick = i, s === e.step - 1 && O(c, "active"), C(c), c.innerHTML = "&nbsp;", c.setAttribute("data-step-number", a.toString()), l.appendChild(c), o.appendChild(l);
  }
  return n.appendChild(o), n;
}
function tt(t, e, n) {
  var o = r(".".concat("introjs-progress", " .").concat("introjs-progressbar"), t);
  if (o) {
    var i = Z(e, n);
    o.style.cssText = "width:".concat(i, "%;"), o.setAttribute("aria-valuenow", i.toString());
  }
}
function et(t, o) {
  var i, s, l;
  return e(this, void 0, void 0, function () {
    var u,
      p,
      h,
      f,
      g,
      v,
      b,
      m,
      j,
      k,
      x,
      A,
      E,
      T,
      _,
      H,
      N,
      L,
      P,
      R,
      B,
      M,
      D,
      z,
      F,
      J = this;
    return n(this, function (V) {
      switch (V.label) {
        case 0:
          return null === (i = t.callback("change")) || void 0 === i || i.call(t, o.element), u = a("introjs-helperLayer"), p = a("introjs-tooltipReferenceLayer"), h = "introjs-helperLayer", "string" == typeof o.highlightClass && (h += " ".concat(o.highlightClass)), "string" == typeof t.getOption("highlightClass") && (h += " ".concat(t.getOption("highlightClass"))), null !== u && null !== p ? (b = c("introjs-tooltiptext", p), m = c("introjs-tooltip-title", p), j = c("introjs-arrow", p), k = c("introjs-tooltip", p), v = c("introjs-skipbutton", p), g = c("introjs-prevbutton", p), f = c("introjs-nextbutton", p), O(u, h), k.style.opacity = "0", k.style.display = "none", K(t.getOption("scrollToElement"), o.element), R = t.getOption("helperElementPadding"), U(t.getTargetElement(), u, o, R), U(t.getTargetElement(), p, o, R), Q(), Y && window.clearTimeout(Y), x = a("introjs-helperNumberLayer", p), Y = window.setTimeout(function () {
            var e;
            null !== x && (x.innerHTML = "".concat(o.step, " ").concat(t.getOption("stepNumbersOfLabel"), " ").concat(t.getSteps().length)), b.innerHTML = o.intro || "", m.innerHTML = o.title || "", k.style.display = "block", I(k, j, o.element, o.position, t.getOption("positionPrecedence"), t.getOption("showStepNumbers"), t.getOption("autoPosition"), null !== (e = o.tooltipClass) && void 0 !== e ? e : t.getOption("tooltipClass")), function (t, e, n) {
              if (t) {
                var o = r(".".concat("introjs-bullets", " li > a.").concat("active"), e),
                  i = r(".".concat("introjs-bullets", " li > a[").concat("data-step-number", '="').concat(n.step, '"]'), e);
                o && i && (o.className = "", O(i, "active"));
              }
            }(t.getOption("showBullets"), p, o), tt(p, t.getCurrentStep(), t.getSteps().length), k.style.opacity = "1", (f && new RegExp("introjs-donebutton", "gi").test(f.className) || f) && f.focus(), W(t.getOption("scrollToElement"), o.scrollTo, t.getOption("scrollPadding"), o.element, b);
          }, 350)) : (A = w("div", {
            className: h
          }), E = w("div", {
            className: "introjs-tooltipReferenceLayer"
          }), T = w("div", {
            className: "introjs-arrow"
          }), _ = w("div", {
            className: "introjs-tooltip"
          }), H = w("div", {
            className: "introjs-tooltiptext"
          }), N = w("div", {
            className: "introjs-tooltip-header"
          }), L = w("h1", {
            className: "introjs-tooltip-title"
          }), P = w("div"), y(A, {
            "box-shadow": "0 0 1px 2px rgba(33, 33, 33, 0.8), rgba(33, 33, 33, ".concat(t.getOption("overlayOpacity").toString(), ") 0 0 0 5000px")
          }), K(t.getOption("scrollToElement"), o.element), R = t.getOption("helperElementPadding"), U(t.getTargetElement(), A, o, R), U(t.getTargetElement(), E, o, R), X(t.getTargetElement(), A, !0), X(t.getTargetElement(), E), H.innerHTML = o.intro, L.innerHTML = o.title, O(P, "introjs-tooltipbuttons"), !1 === t.getOption("showButtons") && (P.style.display = "none"), N.appendChild(L), _.appendChild(N), _.appendChild(H), t.getOption("dontShowAgain") && (B = w("div", {
            className: "introjs-dontShowAgain"
          }), (M = w("input", {
            type: "checkbox",
            id: "introjs-dontShowAgain",
            name: "introjs-dontShowAgain"
          })).onchange = function (e) {
            t.setDontShowAgain(e.target.checked);
          }, (D = w("label", {
            htmlFor: "introjs-dontShowAgain"
          })).innerText = t.getOption("dontShowAgainLabel"), B.appendChild(M), B.appendChild(D), _.appendChild(B)), _.appendChild($(t, o)), _.appendChild(function (t) {
            var e = w("div");
            O(e, "introjs-progress"), !1 === t.getOption("showProgress") && (e.style.display = "none");
            var n = w("div", {
              className: "introjs-progressbar"
            });
            t.getOption("progressBarAdditionalClass") && S(n, t.getOption("progressBarAdditionalClass"));
            var o = Z(t.getCurrentStep(), t.getSteps().length);
            return n.setAttribute("role", "progress"), n.setAttribute("aria-valuemin", "0"), n.setAttribute("aria-valuemax", "100"), n.setAttribute("aria-valuenow", o.toString()), n.style.cssText = "width:".concat(o, "%;"), e.appendChild(n), e;
          }(t)), z = w("div"), !0 === t.getOption("showStepNumbers") && (O(z, "introjs-helperNumberLayer"), z.innerHTML = "".concat(o.step, " ").concat(t.getOption("stepNumbersOfLabel"), " ").concat(t.getSteps().length), _.appendChild(z)), _.appendChild(T), E.appendChild(_), (f = w("a")).onclick = function () {
            return e(J, void 0, void 0, function () {
              var e;
              return n(this, function (n) {
                switch (n.label) {
                  case 0:
                    return t.isLastStep() ? [3, 2] : [4, nt(t)];
                  case 1:
                    return n.sent(), [3, 5];
                  case 2:
                    return new RegExp("introjs-donebutton", "gi").test(f.className) ? [4, null === (e = t.callback("complete")) || void 0 === e ? void 0 : e.call(t, t.getCurrentStep(), "done")] : [3, 5];
                  case 3:
                    return n.sent(), [4, t.exit()];
                  case 4:
                    n.sent(), n.label = 5;
                  case 5:
                    return [2];
                }
              });
            });
          }, C(f), f.innerHTML = t.getOption("nextLabel"), (g = w("a")).onclick = function () {
            return e(J, void 0, void 0, function () {
              return n(this, function (e) {
                switch (e.label) {
                  case 0:
                    return t.getCurrentStep() > 0 ? [4, ot(t)] : [3, 2];
                  case 1:
                    e.sent(), e.label = 2;
                  case 2:
                    return [2];
                }
              });
            });
          }, C(g), g.innerHTML = t.getOption("prevLabel"), C(v = w("a", {
            className: "introjs-skipbutton"
          })), v.innerHTML = t.getOption("skipLabel"), v.onclick = function () {
            return e(J, void 0, void 0, function () {
              var e, o;
              return n(this, function (n) {
                switch (n.label) {
                  case 0:
                    return t.isLastStep() ? [4, null === (e = t.callback("complete")) || void 0 === e ? void 0 : e.call(t, t.getCurrentStep(), "skip")] : [3, 2];
                  case 1:
                    n.sent(), n.label = 2;
                  case 2:
                    return [4, null === (o = t.callback("skip")) || void 0 === o ? void 0 : o.call(t, t.getCurrentStep())];
                  case 3:
                    return n.sent(), [4, t.exit()];
                  case 4:
                    return n.sent(), [2];
                }
              });
            });
          }, N.appendChild(v), t.getSteps().length > 1 && P.appendChild(g), P.appendChild(f), _.appendChild(P), I(_, T, o.element, o.position, t.getOption("positionPrecedence"), t.getOption("showStepNumbers"), t.getOption("autoPosition"), null !== (s = o.tooltipClass) && void 0 !== s ? s : t.getOption("tooltipClass")), W(t.getOption("scrollToElement"), o.scrollTo, t.getOption("scrollPadding"), o.element, _)), (F = a("introjs-disableInteraction", t.getTargetElement())) && F.parentNode && F.parentNode.removeChild(F), o.disableInteraction && function (t, e) {
            var n = a("introjs-disableInteraction");
            null === n && (n = w("div", {
              className: "introjs-disableInteraction"
            }), t.getTargetElement().appendChild(n)), U(t.getTargetElement(), n, e, t.getOption("helperElementPadding"));
          }(t, o), 0 === t.getCurrentStep() && t.getSteps().length > 1 ? (f && (O(f, t.getOption("buttonClass"), "introjs-nextbutton"), f.innerHTML = t.getOption("nextLabel")), !0 === t.getOption("hidePrev") ? (g && O(g, t.getOption("buttonClass"), "introjs-prevbutton", "introjs-hidden"), f && S(f, "introjs-fullbutton")) : g && O(g, t.getOption("buttonClass"), "introjs-prevbutton", "introjs-disabled")) : t.isLastStep() || 1 === t.getSteps().length ? (g && O(g, t.getOption("buttonClass"), "introjs-prevbutton"), !0 === t.getOption("hideNext") ? (f && O(f, t.getOption("buttonClass"), "introjs-nextbutton", "introjs-hidden"), g && S(g, "introjs-fullbutton")) : f && (!0 === t.getOption("nextToDone") ? (f.innerHTML = t.getOption("doneLabel"), S(f, t.getOption("buttonClass"), "introjs-nextbutton", "introjs-donebutton")) : O(f, t.getOption("buttonClass"), "introjs-nextbutton", "introjs-disabled"))) : (g && O(g, t.getOption("buttonClass"), "introjs-prevbutton"), f && (O(f, t.getOption("buttonClass"), "introjs-nextbutton"), f.innerHTML = t.getOption("nextLabel"))), g && g.setAttribute("role", "button"), f && f.setAttribute("role", "button"), v && v.setAttribute("role", "button"), f && f.focus(), function (t) {
            S(t, "introjs-showElement");
            var e = d(t, "position");
            "absolute" !== e && "relative" !== e && "sticky" !== e && "fixed" !== e && S(t, "introjs-relativePosition");
          }(o.element), [4, null === (l = t.callback("afterChange")) || void 0 === l ? void 0 : l.call(t, o.element)];
        case 1:
          return V.sent(), [2];
      }
    });
  });
}
function nt(t) {
  var o, i;
  return e(this, void 0, void 0, function () {
    var e;
    return n(this, function (n) {
      switch (n.label) {
        case 0:
          return t.incrementCurrentStep(), e = t.getStep(t.getCurrentStep()), !0, [4, null === (o = t.callback("beforeChange")) || void 0 === o ? void 0 : o.call(t, e && e.element, t.getCurrentStep(), t.getDirection())];
        case 1:
          return !1 === n.sent() ? (t.decrementCurrentStep(), [2, !1]) : t.isEnd() ? [4, null === (i = t.callback("complete")) || void 0 === i ? void 0 : i.call(t, t.getCurrentStep(), "end")] : [3, 4];
        case 2:
          return n.sent(), [4, t.exit()];
        case 3:
          return n.sent(), [2, !1];
        case 4:
          return [4, et(t, e)];
        case 5:
          return n.sent(), [2, !0];
      }
    });
  });
}
function ot(t) {
  var o;
  return e(this, void 0, void 0, function () {
    var e;
    return n(this, function (n) {
      switch (n.label) {
        case 0:
          return t.getCurrentStep() <= 0 ? [2, !1] : (t.decrementCurrentStep(), e = t.getStep(t.getCurrentStep()), !0, [4, null === (o = t.callback("beforeChange")) || void 0 === o ? void 0 : o.call(t, e && e.element, t.getCurrentStep(), t.getDirection())]);
        case 1:
          return !1 === n.sent() ? (t.incrementCurrentStep(), [2, !1]) : [4, et(t, e)];
        case 2:
          return n.sent(), [2, !0];
      }
    });
  });
}
var it = function (t) {
  var e,
    n = [];
  if (null === (e = t.getOption("steps")) || void 0 === e ? void 0 : e.length) for (var o = 0, l = t.getOption("steps"); o < l.length; o++) {
    var c = i(l[o]);
    if (c.step = n.length + 1, c.title = c.title || "", "string" == typeof c.element && (c.element = r(c.element) || void 0), !c.element) {
      var u = a("introjsFloatingElement");
      u || (u = w("div", {
        className: "introjsFloatingElement"
      }), document.body.appendChild(u)), c.element = u, c.position = "floating";
    }
    c.position = c.position || t.getOption("tooltipPosition"), c.scrollTo = c.scrollTo || t.getOption("scrollTo"), void 0 === c.disableInteraction && (c.disableInteraction = t.getOption("disableInteraction")), null !== c.element && n.push(c);
  } else {
    var p = Array.from(s("*[".concat("data-intro", "]"), t.getTargetElement()));
    if (p.length < 1) return [];
    for (var h = [], d = 0, f = p; d < f.length; d++) {
      var g = f[d];
      if ((!t.getOption("group") || g.getAttribute("data-intro-group") === t.getOption("group")) && "none" !== g.style.display) {
        var v = parseInt(g.getAttribute("data-step") || "0", 10),
          b = t.getOption("disableInteraction");
        g.hasAttribute("data-disable-interaction") && (b = !!g.getAttribute("data-disable-interaction"));
        var m = {
          step: v,
          element: g,
          title: g.getAttribute("data-title") || "",
          intro: g.getAttribute("data-intro") || "",
          tooltipClass: g.getAttribute("data-tooltip-class") || void 0,
          highlightClass: g.getAttribute("data-highlight-class") || void 0,
          position: g.getAttribute("data-position") || t.getOption("tooltipPosition"),
          scrollTo: g.getAttribute("data-scroll-to") || t.getOption("scrollTo"),
          disableInteraction: b
        };
        v > 0 ? n[v - 1] = m : h.push(m);
      }
    }
    for (var y = 0; h.length > 0; y++) if (void 0 === n[y]) {
      var C = h.shift();
      if (!C) break;
      C.step = y + 1, n[y] = C;
    }
  }
  return (n = n.filter(function (t) {
    return t;
  })).sort(function (t, e) {
    return t.step - e.step;
  }), n;
};
var rt = function (t) {
    return e(void 0, void 0, void 0, function () {
      var o, i;
      return n(this, function (r) {
        switch (r.label) {
          case 0:
            return t.isActive() ? t.hasStarted() ? [2, !1] : [4, null === (i = t.callback("start")) || void 0 === i ? void 0 : i.call(t, t.getTargetElement())] : [2, !1];
          case 1:
            return r.sent(), 0 === (o = it(t)).length ? [2, !1] : (t.setSteps(o), function (t) {
              var o = this,
                i = w("div", {
                  className: "introjs-overlay"
                });
              y(i, {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                position: "fixed"
              }), t.getTargetElement().appendChild(i), !0 === t.getOption("exitOnOverlayClick") && (y(i, {
                cursor: "pointer"
              }), i.onclick = function () {
                return e(o, void 0, void 0, function () {
                  return n(this, function (e) {
                    switch (e.label) {
                      case 0:
                        return [4, t.exit()];
                      case 1:
                        return e.sent(), [2];
                    }
                  });
                });
              });
            }(t), [4, nt(t)]);
          case 2:
            return r.sent(), [2, !0];
          case 3:
            return [2, !1];
        }
      });
    });
  },
  st = function (t) {
    t && t.parentElement && t.parentElement.removeChild(t);
  };
function at(t, o) {
  var i, r;
  return void 0 === o && (o = !1), e(this, void 0, void 0, function () {
    var s, c, u, p, h, d, f, g, v, b;
    return n(this, function (m) {
      switch (m.label) {
        case 0:
          return s = t.getTargetElement(), c = !0, [4, null === (i = t.callback("beforeExit")) || void 0 === i ? void 0 : i.call(t, s)];
        case 1:
          if (c = m.sent(), !o && !1 === c) return [2, !1];
          if ((u = Array.from(l("introjs-overlay", s))) && u.length) for (p = 0, h = u; p < h.length; p++) d = h[p], st(d);
          return f = a("introjs-tooltipReferenceLayer", s), st(f), g = a("introjs-disableInteraction", s), st(g), v = a("introjsFloatingElement", s), st(v), Q(), b = a("introjs-helperLayer", s), [4, (w = b, e(void 0, void 0, void 0, function () {
            return n(this, function (t) {
              return w ? (y(w, {
                opacity: "0"
              }), [2, new Promise(function (t) {
                setTimeout(function () {
                  try {
                    st(w);
                  } catch (t) {} finally {
                    t();
                  }
                }, 500);
              })]) : [2];
            });
          }))];
        case 2:
          return m.sent(), [4, null === (r = t.callback("exit")) || void 0 === r ? void 0 : r.call(t)];
        case 3:
          return m.sent(), t.setCurrentStep(-1), [2, !0];
      }
      var w;
    });
  });
}
function lt(t, e, n) {
  var o,
    i = ((o = {})[t] = e, o.path = "/", o.expires = void 0, o);
  if (n) {
    var r = new Date();
    r.setTime(r.getTime() + 24 * n * 60 * 60 * 1e3), i.expires = r.toUTCString();
  }
  var s = [];
  for (var a in i) s.push("".concat(a, "=").concat(i[a]));
  return document.cookie = s.join("; "), ct(t);
}
function ct(t) {
  return (e = {}, document.cookie.split(";").forEach(function (t) {
    var n = t.split("="),
      o = n[0],
      i = n[1];
    e[o.trim()] = i;
  }), e)[t];
  var e;
}
function ut(t, e, n) {
  t ? lt(e, "true", n) : lt(e, "", -1);
}
function pt(t, e) {
  var n,
    o = t.getCurrentStep();
  if (null != o && -1 != o) {
    var i = t.getStep(o),
      r = c("introjs-tooltipReferenceLayer"),
      s = c("introjs-helperLayer"),
      l = a("introjs-disableInteraction"),
      u = t.getTargetElement(),
      p = t.getOption("helperElementPadding");
    U(u, s, i, p), U(u, r, i, p), l && U(u, l, i, p), e && (t.setSteps(it(t)), function (t, e) {
      if (t.getOption("showBullets")) {
        var n = a("introjs-bullets");
        n && n.parentNode && n.parentNode.replaceChild($(t, e), n);
      }
    }(t, i), tt(r, o, t.getSteps().length));
    var h = document.querySelector(".introjs-arrow"),
      d = document.querySelector(".introjs-tooltip");
    return d && h && I(d, h, i.element, i.position, t.getOption("positionPrecedence"), t.getOption("showStepNumbers"), t.getOption("autoPosition"), null !== (n = i.tooltipClass) && void 0 !== n ? n : t.getOption("tooltipClass")), t;
  }
}
var ht = function () {
    function t(t, e) {
      this._steps = [], this._currentStep = -1, this.callbacks = {}, this._targetElement = m(t), this._options = e ? p(this._options, e) : {
        steps: [],
        isActive: !0,
        nextLabel: "Next",
        prevLabel: "Back",
        skipLabel: "×",
        doneLabel: "Done",
        hidePrev: !1,
        hideNext: !1,
        nextToDone: !0,
        tooltipPosition: "bottom",
        tooltipClass: "",
        group: "",
        highlightClass: "",
        exitOnEsc: !0,
        exitOnOverlayClick: !0,
        showStepNumbers: !1,
        stepNumbersOfLabel: "of",
        keyboardNavigation: !0,
        showButtons: !0,
        showBullets: !0,
        showProgress: !1,
        scrollToElement: !0,
        scrollTo: "element",
        scrollPadding: 30,
        overlayOpacity: .5,
        autoPosition: !0,
        positionPrecedence: ["bottom", "top", "right", "left"],
        disableInteraction: !1,
        dontShowAgain: !1,
        dontShowAgainLabel: "Don't show this again",
        dontShowAgainCookie: "introjs-dontShowAgain",
        dontShowAgainCookieDays: 365,
        helperElementPadding: 10,
        buttonClass: "introjs-button",
        progressBarAdditionalClass: ""
      };
    }
    return t.prototype.callback = function (t) {
      var e = this.callbacks[t];
      if (h(e)) return e;
    }, t.prototype.goToStep = function (t) {
      return e(this, void 0, void 0, function () {
        return n(this, function (e) {
          switch (e.label) {
            case 0:
              return this.setCurrentStep(t - 2), [4, nt(this)];
            case 1:
              return e.sent(), [2, this];
          }
        });
      });
    }, t.prototype.goToStepNumber = function (t) {
      return e(this, void 0, void 0, function () {
        var e;
        return n(this, function (n) {
          switch (n.label) {
            case 0:
              for (e = 0; e < this._steps.length; e++) if (this._steps[e].step === t) {
                this.setCurrentStep(e - 1);
                break;
              }
              return [4, nt(this)];
            case 1:
              return n.sent(), [2, this];
          }
        });
      });
    }, t.prototype.addStep = function (t) {
      return this._options.steps || (this._options.steps = []), this._options.steps.push(t), this;
    }, t.prototype.addSteps = function (t) {
      if (!t.length) return this;
      for (var e = 0, n = t; e < n.length; e++) {
        var o = n[e];
        this.addStep(o);
      }
      return this;
    }, t.prototype.setSteps = function (t) {
      return this._steps = t, this;
    }, t.prototype.getSteps = function () {
      return this._steps;
    }, t.prototype.getStep = function (t) {
      return this._steps[t];
    }, t.prototype.getCurrentStep = function () {
      return this._currentStep;
    }, t.prototype.currentStep = function () {
      return this._currentStep;
    }, t.prototype.setCurrentStep = function (t) {
      return t >= this._currentStep ? this._direction = "forward" : this._direction = "backward", this._currentStep = t, this;
    }, t.prototype.incrementCurrentStep = function () {
      return -1 === this.getCurrentStep() ? this.setCurrentStep(0) : this.setCurrentStep(this.getCurrentStep() + 1), this;
    }, t.prototype.decrementCurrentStep = function () {
      return this.getCurrentStep() > 0 && this.setCurrentStep(this._currentStep - 1), this;
    }, t.prototype.getDirection = function () {
      return this._direction;
    }, t.prototype.nextStep = function () {
      return e(this, void 0, void 0, function () {
        return n(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, nt(this)];
            case 1:
              return t.sent(), [2, this];
          }
        });
      });
    }, t.prototype.previousStep = function () {
      return e(this, void 0, void 0, function () {
        return n(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, ot(this)];
            case 1:
              return t.sent(), [2, this];
          }
        });
      });
    }, t.prototype.isEnd = function () {
      return this.getCurrentStep() >= this._steps.length;
    }, t.prototype.isLastStep = function () {
      return this.getCurrentStep() === this._steps.length - 1;
    }, t.prototype.getTargetElement = function () {
      return this._targetElement;
    }, t.prototype.setOptions = function (t) {
      return this._options = p(this._options, t), this;
    }, t.prototype.setOption = function (t, e) {
      return this._options = u(this._options, t, e), this;
    }, t.prototype.getOption = function (t) {
      return this._options[t];
    }, t.prototype.clone = function () {
      return new t(this._targetElement, this._options);
    }, t.prototype.isActive = function () {
      return (!this.getOption("dontShowAgain") || (t = this.getOption("dontShowAgainCookie"), "" === (e = ct(t)) || "true" !== e)) && this.getOption("isActive");
      var t, e;
    }, t.prototype.hasStarted = function () {
      return this.getCurrentStep() > -1;
    }, t.prototype.setDontShowAgain = function (t) {
      return ut(t, this.getOption("dontShowAgainCookie"), this.getOption("dontShowAgainCookieDays")), this;
    }, t.prototype.enableKeyboardNavigation = function () {
      var t = this;
      return this.getOption("keyboardNavigation") && (this._keyboardNavigationHandler = function (o) {
        return function (t, o) {
          var i;
          return e(this, void 0, void 0, function () {
            var e, r;
            return n(this, function (n) {
              switch (n.label) {
                case 0:
                  return null === (e = void 0 === o.code ? o.which : o.code) && (e = null === o.charCode ? o.keyCode : o.charCode), "Escape" !== e && 27 !== e || !0 !== t.getOption("exitOnEsc") ? [3, 2] : [4, t.exit()];
                case 1:
                  return n.sent(), [3, 16];
                case 2:
                  return "ArrowLeft" !== e && 37 !== e ? [3, 4] : [4, ot(t)];
                case 3:
                  return n.sent(), [3, 16];
                case 4:
                  return "ArrowRight" !== e && 39 !== e ? [3, 6] : [4, nt(t)];
                case 5:
                  return n.sent(), [3, 16];
                case 6:
                  return "Enter" !== e && "NumpadEnter" !== e && 13 !== e ? [3, 16] : (r = o.target || o.srcElement) && r.className.match("introjs-prevbutton") ? [4, ot(t)] : [3, 8];
                case 7:
                  return n.sent(), [3, 15];
                case 8:
                  return r && r.className.match("introjs-skipbutton") ? t.isEnd() ? [4, null === (i = t.callback("complete")) || void 0 === i ? void 0 : i.call(t, t.getCurrentStep(), "skip")] : [3, 10] : [3, 12];
                case 9:
                  n.sent(), n.label = 10;
                case 10:
                  return [4, t.exit()];
                case 11:
                  return n.sent(), [3, 15];
                case 12:
                  return r && r.getAttribute("data-step-number") ? (r.click(), [3, 15]) : [3, 13];
                case 13:
                  return [4, nt(t)];
                case 14:
                  n.sent(), n.label = 15;
                case 15:
                  o.preventDefault ? o.preventDefault() : o.returnValue = !1, n.label = 16;
                case 16:
                  return [2];
              }
            });
          });
        }(t, o);
      }, b.on(window, "keydown", this._keyboardNavigationHandler, !0)), this;
    }, t.prototype.disableKeyboardNavigation = function () {
      return this._keyboardNavigationHandler && (b.off(window, "keydown", this._keyboardNavigationHandler, !0), this._keyboardNavigationHandler = void 0), this;
    }, t.prototype.enableRefreshOnResize = function () {
      var t = this;
      this._refreshOnResizeHandler = function (e) {
        pt(t);
      }, b.on(window, "resize", this._refreshOnResizeHandler, !0);
    }, t.prototype.disableRefreshOnResize = function () {
      this._refreshOnResizeHandler && (b.off(window, "resize", this._refreshOnResizeHandler, !0), this._refreshOnResizeHandler = void 0);
    }, t.prototype.start = function () {
      return e(this, void 0, void 0, function () {
        return n(this, function (t) {
          switch (t.label) {
            case 0:
              return [4, rt(this)];
            case 1:
              return t.sent() && (this.enableKeyboardNavigation(), this.enableRefreshOnResize()), [2, this];
          }
        });
      });
    }, t.prototype.exit = function (t) {
      return e(this, void 0, void 0, function () {
        return n(this, function (e) {
          switch (e.label) {
            case 0:
              return [4, at(this, null != t && t)];
            case 1:
              return e.sent() && (this.disableKeyboardNavigation(), this.disableRefreshOnResize()), [2, this];
          }
        });
      });
    }, t.prototype.refresh = function (t) {
      return pt(this, t), this;
    }, t.prototype.onbeforechange = function (t) {
      return this.onBeforeChange(t);
    }, t.prototype.onBeforeChange = function (t) {
      if (!h(t)) throw new Error("Provided callback for onBeforeChange was not a function");
      return this.callbacks.beforeChange = t, this;
    }, t.prototype.onchange = function (t) {
      this.onChange(t);
    }, t.prototype.onChange = function (t) {
      if (!h(t)) throw new Error("Provided callback for onChange was not a function.");
      return this.callbacks.change = t, this;
    }, t.prototype.onafterchange = function (t) {
      this.onAfterChange(t);
    }, t.prototype.onAfterChange = function (t) {
      if (!h(t)) throw new Error("Provided callback for onAfterChange was not a function");
      return this.callbacks.afterChange = t, this;
    }, t.prototype.oncomplete = function (t) {
      return this.onComplete(t);
    }, t.prototype.onComplete = function (t) {
      if (!h(t)) throw new Error("Provided callback for oncomplete was not a function.");
      return this.callbacks.complete = t, this;
    }, t.prototype.onstart = function (t) {
      return this.onStart(t);
    }, t.prototype.onStart = function (t) {
      if (!h(t)) throw new Error("Provided callback for onstart was not a function.");
      return this.callbacks.start = t, this;
    }, t.prototype.onexit = function (t) {
      return this.onExit(t);
    }, t.prototype.onExit = function (t) {
      if (!h(t)) throw new Error("Provided callback for onexit was not a function.");
      return this.callbacks.exit = t, this;
    }, t.prototype.onskip = function (t) {
      return this.onSkip(t);
    }, t.prototype.onSkip = function (t) {
      if (!h(t)) throw new Error("Provided callback for onskip was not a function.");
      return this.callbacks.skip = t, this;
    }, t.prototype.onbeforeexit = function (t) {
      return this.onBeforeExit(t);
    }, t.prototype.onBeforeExit = function (t) {
      if (!h(t)) throw new Error("Provided callback for onbeforeexit was not a function.");
      return this.callbacks.beforeExit = t, this;
    }, t;
  }(),
  dt = function (e) {
    function n() {
      return null !== e && e.apply(this, arguments) || this;
    }
    return function (e, n) {
      if ("function" != typeof n && null !== n) throw new TypeError("Class extends value " + String(n) + " is not a constructor or null");
      function o() {
        this.constructor = e;
      }
      t(e, n), e.prototype = null === n ? Object.create(n) : (o.prototype = n.prototype, new o());
    }(n, e), n.prototype.addHints = function () {
      console.error("introJs().addHints() is deprecated, please use introJs.hint.addHints() instead.");
    }, n.prototype.addHint = function () {
      console.error("introJs().addHint() is deprecated, please use introJs.hint.addHint() instead.");
    }, n.prototype.removeHints = function () {
      console.error("introJs().removeHints() is deprecated, please use introJs.hint.removeHints() instead.");
    }, n;
  }(ht),
  ft = function (t) {
    return console.warn("introJs() is deprecated. Please use introJs.tour() or introJs.hint() instead."), new dt(t);
  };
ft.tour = function (t) {
  return new ht(t);
}, ft.hint = function (t) {
  return new q(t);
}, ft.version = "8.0.0-beta.1";
export { ft as default };
