<div
  class="work-experience-part {{section.className}} section-container"
  [class.has-icon]="section.title.icon?.state">

  <div class="w-e-t-w-t pb-4">
    <div
      class="work-exp-title-wrap"
      [ngStyle]="{
       backgroundColor: sectionName !== SectionNames.SIDEBAR_SECTION ? tmTheme.sectionMainTitleBgColor : 'transparent'
    }">

      <sf-title-action-banner
        [template]="template.settings"
        [page]="page"
        [pageIndex]="pageIndex"
        [section]="section"
        [sectionIndex]="sectionIndex"
        [sectionName]="sectionName"
        [elRef]="WorkExperienceListRef"
        [(selectedItem)]="selectedItem"
        (openRearrange$)="openRearrange$.emit()"
      />

      @if (section.title.icon?.state) {
        <i class="{{section.title!.icon!.name}}"></i>
      }

      @if (section.title.state) {
        <h2
          contenteditable="true"
          [attr.placeholder]="section.title.pl"
          [class.edit-mode]="selectedItem === section.title"
          [innerHTML]="section.title.text"
          (click)="onSelect(section.title)"
          (keydown.enter)="$event.preventDefault()"
          (blur)="onBlurSectionTitle($event, section)"
          (focus)="onFocusEL($event, section.title.text)"
          [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionMainTitle"
          [ngStyle]="{
            fontSize: appStateService.templateCurrentFontSize.sectionMainTitle + 'px',
            lineHeight: appStateService.templateCurrentFontSize.sectionMainTitleLineHeight + 'px',
            color: getH2Color()
        }"
        ></h2>
      }
    </div>
  </div>


  <div class="work-experience-list" #WorkExperienceListRef>
    <div
      class="work-experience-item"
      [class.mb-0]="lastSection && last"
      *ngFor="let workExp of section.items; let sectionItemIndex = index; let first = first; let last = last"
      [class.edit-mode]="selectedItem === workExp"
      (click)="onSelect(workExp)">
      <div class="work-exp-item-child">
        <sf-tm-editor *ngIf="selectedItem === workExp"/>

        <sf-actions-banner
          [pageIndex]="pageIndex"
          [template]="template.settings"

          [section]="section"
          [sectionIndex]="sectionIndex"
          [sectionName]="sectionName"

          [sectionItem]="workExp"
          [WorkExperienceListRef]="WorkExperienceListRef"
          [sectionItemIndex]="sectionItemIndex"
          [last]="last"
          [first]="first"

          [(selectedItem)]="selectedItem"
        />

        <div class="exp-title">
          <div class="title-wr">
            <h3
              [class.mb-0]="!workExp.subTitle?.state && !workExp.desc?.state && !workExp.achievements?.state && !workExp.address?.state"
              contenteditable="true"
              *ngIf="workExp.title?.state"
              [innerHTML]="workExp.title!.text"
              [attr.placeholder]="workExp.title!.pl"
              (keydown.enter)="$event.preventDefault()"
              (blur)="onBlurSectionItemTitle($event, workExp)"
              (focus)="onFocusEL($event, workExp.title!.text)"
              [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionTitle"
              [ngStyle]="{
                  fontSize: appStateService.templateCurrentFontSize.sectionTitle + 'px',
                  lineHeight: appStateService.templateCurrentFontSize.sectionTitleLineHeight + 'px',

                  color: sectionName === SectionNames.SIDEBAR_SECTION ?
                    (selectedItem === workExp ? tmTheme.sidebar.sectionTitleFontEditModeColor : tmTheme.sidebar.sectionTitleFontColor) : tmTheme.sectionTitleFontColor,
              }"
            ></h3>

            <!-- DATE -->
            @if (workExp.date && sectionName !== SectionNames.SIDEBAR_SECTION) {
              <div
                class="tm-date-container"
                (click)="onSelectDate($event, workExp)"
                (sfClickOutside)="onClickOutsideDate()"
                [ngStyle]="{
                  fontSize: appStateService.templateCurrentFontSize.sectionDateAndAddress + 'px',
                  lineHeight: appStateService.templateCurrentFontSize.sectionDateAndAddressLineHeight + 'px',
                  color: tmTheme.dateColor,
              }">
                <div class="rs mb-4" *ngIf="workExp.date!.state">{{ getDateString(workExp) }}</div>
                <div class="tm-date-wrapper" *ngIf="selectedDate === workExp.date">
                  <sf-tm-date-picker
                    [(period)]="workExp.date"
                    (periodChange)="saveChanges()"/>
                </div>
              </div>
            }
          </div>

          <div class="sb-title-wr">
            <!-- Subtitle -->
            <h4
              class="{{!workExp.desc?.state && !workExp.achievements?.state ? 'mb-0' : ''}}"
              contenteditable="true"
              *ngIf="workExp.subTitle?.state"
              [innerHTML]="workExp.subTitle?.text"
              [attr.placeholder]="workExp.subTitle!.pl"
              (keydown.enter)="$event.preventDefault()"
              (blur)="onBlurSectionItemSubTitle($event, workExp)"
              (focus)="onFocusEL($event, workExp.subTitle!.text)"
              [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionSubtitle"
              [ngStyle]="{
                  fontSize: appStateService.templateCurrentFontSize.sectionSubtitle + 'px',
                  lineHeight: appStateService.templateCurrentFontSize.sectionSubtitleLineHeight + 'px',
                  color: sectionName === SectionNames.SIDEBAR_SECTION ?
                         (selectedItem === workExp ?  tmTheme.sidebar.sectionSubtitleEditModeFontColor :  tmTheme.sidebar.sectionSubtitleFontColor)
                         : tmTheme.sectionSubtitleFontColor
              }"
            ></h4>

            <!-- DATE INSIDE SIDEBAR-->
            @if (workExp.date && sectionName === SectionNames.SIDEBAR_SECTION) {
              <div
                class="tm-date-container"
                (click)="onSelectDate($event, workExp)"
                (sfClickOutside)="onClickOutsideDate()"
                [ngStyle]="{
                  fontSize: appStateService.templateCurrentFontSize.sectionDateAndAddress + 'px',
                  lineHeight: appStateService.templateCurrentFontSize.sectionDateAndAddressLineHeight + 'px',
                  color: tmTheme.sidebar.dateColor,
              }">
                <div class="rs mb-2" *ngIf="workExp.date!.state">{{ getDateString(workExp) }}</div>
                <div class="tm-date-wrapper" *ngIf="selectedDate === workExp.date">
                  <sf-tm-date-picker
                    [(period)]="workExp.date"
                    (periodChange)="saveChanges()"/>
                </div>
              </div>
            }

            <!-- Address -->
            @if (workExp.address?.state) {
              <div
                (mouseup)="handleMouseUp(workExp.address)"
                class="rs tm-sec-address {{!workExp.desc?.state && !workExp.achievements?.state ? 'mb-0' : ''}}"
                contenteditable="true"
                [innerHTML]="workExp.address?.text"
                [attr.placeholder]="workExp.address?.pl"
                (keydown.enter)="$event.preventDefault()"
                (blur)="onBlurSectionItemDesc($event, workExp.address)"
                (focus)="onFocusEL($event, workExp.address!.text)"
                [sfContentEditableMaxLength]="appStateService.textMaxSizes.address"
                [ngStyle]="{
                  fontSize: appStateService.templateCurrentFontSize.sectionDateAndAddress + 'px',
                  lineHeight: appStateService.templateCurrentFontSize.sectionDateAndAddressLineHeight + 'px',
                  color: sectionName === SectionNames.SIDEBAR_SECTION ? tmTheme.sidebar.countryColor : tmTheme.countryColor
              }"
              ></div>
            }
          </div>
        </div>

        <!-- Description -->
        @if (workExp.desc?.state) {
          <p
            [ngStyle]="{
              fontSize: appStateService.templateCurrentFontSize.sectionDesc + 'px',
              lineHeight: appStateService.templateCurrentFontSize.sectionDescLineHeight + 'px',
          }"
            (mouseup)="handleMouseUp(workExp.desc)"
            (sfCtrlA)="handleCtrlA(template.settings.summary.desc)"
            class="exp-desc {{!workExp.achievements?.state ? 'mb-0' : ''}}"
            contenteditable="true"
            [innerHTML]="workExp.desc!.text"
            [attr.placeholder]="workExp.desc!.pl"
            (keydown.enter)="onEnterDesc($event)"
            (keydown.backspace)="onBackspaceDesc($event)"
            (keydown.delete)="onBackspaceDesc($event)"
            (blur)="onBlurSectionItemDesc($event, workExp.desc)"
            (focus)="onFocusEL($event, workExp.desc!.text)"
            [sfContentEditableMaxLength]="appStateService.textMaxSizes.sectionDesc"
            sfClickableLinks
          ></p>
        }

        <!-- achievements -->
        <div class="resp-area" *ngIf="workExp.achievements?.state">
          <div
            class="achievements"
            contenteditable="true"
            [ngStyle]="{
              fontSize: appStateService.templateCurrentFontSize.sectionAchievement + 'px',
              lineHeight: appStateService.templateCurrentFontSize.sectionAchievementLineHeight + 'px',
            }"
            [innerHTML]="workExp.achievements!.title!.text"
            [attr.placeholder]="workExp.achievements!.title!.pl"
            (keydown.enter)="$event.preventDefault()"
            (focus)="onFocusEL($event, workExp.achievements!.title!.text)"
            (blur)="onBlurAchievements($event, workExp)"
            [sfContentEditableMaxLength]="appStateService.textMaxSizes.achievementTitle"
          ></div>

          <ul
            #AchievementsRef
            class="ach-drag-boundary"
            [style.gridTemplateColumns]="'repeat(' + workExp.achievements?.column?.cols + ', 1fr)'"
            [class.cols-2]="workExp.achievements?.column?.cols === 2"
            [class.cols-3]="workExp.achievements?.column?.cols === 3">
            <li
              *ngFor="let achievement of workExp.achievements!.items; let achIndex = index;"
              (mouseup)="handleMouseUp(achievement)"
              (sfCtrlA)="handleCtrlA(template.settings.summary.desc)"
              contenteditable="true"
              [ngStyle]="{
                 fontSize: appStateService.templateCurrentFontSize.sectionAchList + 'px',
                 lineHeight: appStateService.templateCurrentFontSize.sectionAchListLineHeight + 'px',
              }"
              [innerHTML]="achievement.text"
              [attr.placeholder]="workExp.achievements?.achItemPl"
              (focus)="onFocusEL($event, achievement.text)"
              (blur)="onBlurAchievement($event, achievement)"
              (keydown.enter)="onAddAchievement($event, workExp.achievements, achIndex, AchievementsRef)"
              (keydown.backspace)="onRemoveAchievement($event, workExp.achievements, achIndex, AchievementsRef)"
              (keydown.arrowUp)="onAchUpArrow($event, achIndex, AchievementsRef)"
              (keydown.delete)="onDeleteAchievement($event, workExp.achievements, achIndex, AchievementsRef)"
              (keydown.arrowDown)="onAchDownArrow($event, achIndex, AchievementsRef)"
              [sfContentEditableMaxLength]="appStateService.textMaxSizes.achievement"
              sfClickableLinks
            ></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sf-line" *ngIf="!lastSection"></div>

