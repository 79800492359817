<ng-container [ngSwitch]="template.templateTopPartType">
  <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="Type_1_Ref"/>
  <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="Type_2_Ref"/>
  <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="Type_1_Ref"/>
  <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="Type_1_Ref"/>
  <ng-container *ngSwitchCase="5" [ngTemplateOutlet]="Type_5_Ref"/>
  <ng-container *ngSwitchCase="6" [ngTemplateOutlet]="Type_1_Ref"/>
  <ng-container *ngSwitchCase="7" [ngTemplateOutlet]="Type_7_Ref"/>
</ng-container>

<ng-template #Type_1_Ref>
  <div class="tm-top-wrapper tm-sidebar-top-wrapper-type-1">
    <div
      class="right-side-tm-top-part"
      [ngStyle]="{
        backgroundColor: tmTheme.sidebar.topPartBgColor
      }">
      <div class="top-part">
        <ng-container [ngTemplateOutlet]="TmTitleRef"/>

        <sf-tm-social-info *ngIf="!template.socialInfo.onSidebar" [template]="template" [tmTheme]="tmTheme" [type]="1"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>

    <div class="sf-line"></div>

    <ng-container [ngTemplateOutlet]="TmSummaryRef"/>
  </div>
</ng-template>

<ng-template #Type_2_Ref>
  <div class="tm-top-wrapper {{template.summary.state ? '' : 'mb-16-minus'}}">
    <div
      class="right-side-tm-top-part"
      [ngStyle]="{
        backgroundColor: tmTheme.sidebar.topPartBgColor
      }">
      <div class="top-part">
        <ng-container [ngTemplateOutlet]="TmTitleRef"/>

        <sf-tm-social-info *ngIf="!template.socialInfo.onSidebar" [template]="template" [tmTheme]="tmTheme" [type]="1"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>

    <div class="sf-line"></div>

    <ng-container [ngTemplateOutlet]="TmSummaryRef"/>
  </div>
</ng-template>

<ng-template #Type_5_Ref>
  <div class="tm-top-wrapper tm-sidebar-top-wrapper-type-5">
    <div
      class="right-side-tm-top-part {{template.summary.state ? '' : 'mb-30'}}"
      [ngStyle]="{
        backgroundColor: tmTheme.sidebar.topPartBgColor
      }">
      <div class="top-part">
        <ng-container [ngTemplateOutlet]="TmTitleRef"/>

        <sf-tm-social-info *ngIf="!template.socialInfo.onSidebar" [template]="template" [tmTheme]="tmTheme" [type]="1"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>

    <ng-container [ngTemplateOutlet]="TmSummaryRef"/>
  </div>
</ng-template>

<ng-template #Type_7_Ref>
  <ng-container>
    <div class="tm-top-wrapper tm-sidebar-top-wrapper-type-7 {{template.summary.state ? '' : 'mb-30'}}">
      <div
        class="right-side-tm-top-part"
        [ngStyle]="{
          backgroundColor: tmTheme.sidebar.topPartBgColor
        }">
        <div class="top-part">
          <ng-container [ngTemplateOutlet]="ImgRef"/>

          <ng-container [ngTemplateOutlet]="TmTitleRef"/>
        </div>

        @if (!template.socialInfo.onSidebar) {
          <ng-container [ngTemplateOutlet]="SocialInfoLineRef"/>
        }
      </div>

      <ng-container [ngTemplateOutlet]="TmSummaryRef"/>
    </div>
  </ng-container>
</ng-template>

<ng-template #SocialInfoRef>
  <sf-tm-social-info [template]="template" [tmTheme]="tmTheme" [type]="2"/>
</ng-template>

<ng-template #SocialInfoLineRef>
  <sf-tm-social-info [template]="template" [tmTheme]="tmTheme" [type]="4"/>
</ng-template>

<ng-template #ImgRef>
  @if (template.img.state) {
    @if (!forPrint || (forPrint && (appStateService.user?.image || template.img.src))) {
      <sf-template-img [stroke]="tmTheme.sidebar.emptyImgColor" [template]="template"/>
    }
  }
</ng-template>

<ng-template #TmTitleRef>
  <sf-tm-name-part
    [template]="template"
    [tmTheme]="tmTheme"
    [selectedItem]="selectedItem"
    (selectedItemChange)="onSelect($event)"/>
</ng-template>

<ng-template #TmSummaryRef>
  <sf-tm-summary
    [template]="template"
    [tmTheme]="tmTheme"
    [selectedItem]="selectedItem"
    (selectedItemChange)="onSelect($event)"/>
</ng-template>
