import {TemplateTypes} from "@app/shared/enums";
import {AppStateService} from "@app/shared/services";
import {Component, inject, Input} from '@angular/core';
import {TmSettings} from "@app/shared/types/template-types";
import {NgIf, NgStyle, NgTemplateOutlet} from "@angular/common";
import {CoverLetterSettingsThemeColor, TemplateSettingsThemeColor} from "@app/shared/interfaces";

@Component({
  selector: 'sf-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss'],
  imports: [
    NgIf,
    NgStyle,
    NgTemplateOutlet
  ],
  standalone: true
})
export class BrandingComponent {
  private readonly appStateService = inject(AppStateService);

  protected readonly TemplateTypes = TemplateTypes;

  @Input({required: true}) public template!: TmSettings;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor | CoverLetterSettingsThemeColor;
  @Input({required: true}) public forPrint!: boolean;

  protected onRemoveBranding() {
    this.template.branding.enabled = false;
    this.appStateService.upgradeBannerState$.next();
    this.saveChanges();
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }
}
