import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {WorkExpSectionStPipe} from "@app/shared/pipes";
import {NzPopconfirmModule} from "ng-zorro-antd/popconfirm";
import {ClickOutsideDirective} from "@app/shared/directives";
import {SectionNames, TemplateTypes} from "@app/shared/enums";
import {allSectionsItemsDefaults} from "@app/shared/constants";
import {TemplateConfigs, TemplateSection} from "@app/shared/interfaces";
import {AppStateService, TemplateLogicService} from "@app/shared/services";
import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {cloneObject, getTmSectionsByType, selectionToTheEnd, swapItems} from "@app/shared/helpers";

interface AchColCount {
  className: string;
  count: number;
}

@Component({
  selector: 'sf-actions-banner',
  templateUrl: './actions-banner.component.html',
  styleUrls: ['./actions-banner.component.scss'],
  imports: [
    NgIf,
    NzPopconfirmModule,
    ClickOutsideDirective,
    FormsModule,
    WorkExpSectionStPipe,
    NgForOf,
  ],
  standalone: true
})
export class ActionsBannerComponent {
  protected readonly appStateService = inject(AppStateService);
  private readonly templateLogicService = inject(TemplateLogicService);

  @Input({required: true}) public pageIndex!: number;
  @Input({required: true}) public template!: TemplateConfigs;

  @Input({required: true}) public section!: TemplateSection;
  @Input({required: true}) public sectionIndex!: number;
  @Input({required: true}) public sectionName!: SectionNames;

  @Input({required: true}) public sectionItem!: any /*TemplateSectionItem*/;
  @Input({required: true}) public WorkExperienceListRef!: HTMLDivElement;
  @Input({required: true}) public sectionItemIndex!: number;
  @Input({required: true}) public first!: boolean;
  @Input({required: true}) public last!: boolean;

  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter<any>();

  protected selectedListItem: any = null;
  protected sectionSettingsSelected = false;

  protected readonly achColCounts: AchColCount[] = [
    {className: 'sf-icon-one-line icon-tag-small  mr-12', count: 1},
    {className: 'sf-icon-two-lines icon-tag-small  mr-12', count: 2},
    {className: 'sf-icon-three-lines icon-tag-small mr-12', count: 3},
  ];

  protected onMoveDown(section: TemplateSection, index: number, workExperienceListRef: HTMLElement) {
    swapItems(section.items, index, index + 1);

    const el = (workExperienceListRef.children[index] as HTMLElement);
    const focusEL = el.querySelector('h3');
    if (focusEL) {
      focusEL.focus();
      selectionToTheEnd(focusEL);
    }

    this.saveChanges();
  }

  protected onMoveUp(section: TemplateSection, index: number, workExperienceListRef: HTMLElement) {
    swapItems(section.items, index, index - 1);

    setTimeout(() => {
      const el = (workExperienceListRef.children[index - 1] as HTMLElement);
      const focusEL = el.querySelector('h3');
      if (focusEL) {
        focusEL.focus();
        selectionToTheEnd(focusEL);
      }

      this.saveChanges();
    });
  }

  protected onAddNewEntry(section: TemplateSection, index: number, workExperienceListRef: HTMLElement) {
    section.items.splice(index + 1, 0, cloneObject(allSectionsItemsDefaults[section.type]));

    setTimeout(() => {
      const el = (workExperienceListRef.children[index + 1] as HTMLElement);
      el.click();
      el.querySelector('h3')?.focus();
      this.templateLogicService.splitToPages(this.template);
      this.saveChanges();
    });
  }

  protected onRemoveSectionItem(section: TemplateSection, sectionItemIndex: number) {
    section.items.splice(sectionItemIndex, 1);

    if (section.items.length === 0) {
      const page = this.template.pages.items[this.pageIndex];
      page[this.sectionName].items.splice(this.sectionIndex, 1);
    }

    this.selectedItem = null;
    this.selectedItemChange.emit(this.selectedItem);

    setTimeout(() => this.templateLogicService.splitToPages(this.template));
    this.saveChanges();
  }

  protected onChangeAchColumnsCount(workExp: any, count: { count: number; className: string }) {
    workExp.achievements.column.cols = count.count;
    this.templateLogicService.splitToPages(this.template);
    this.saveChanges();
  }

  protected onRemoveLastSectionItem(pageIndex: number, sectionIndex: number, sectionName: SectionNames) {
    const page = this.template.pages.items[pageIndex];
    const [deletedSection] = page[sectionName].items.splice(sectionIndex, 1);

    this.template.allSections.items.push(deletedSection);

    if (this.template.pages.items.length > 1) {

      switch (this.template.type) {
        case TemplateTypes.ONE_ROW: {
          if (page.section.items.length === 0) {
            this.template.pages.items.splice(pageIndex, 1);
          }
          break;
        }
        case TemplateTypes.SIDEBAR: {
          if (page.sidebarSection.items.length === 0 && page.sidebarRightSection.items.length === 0) {
            this.template.pages.items.splice(pageIndex, 1);
          }
          break;
        }
        case TemplateTypes.TWO_ROWS: {
          if (page.twoRowLeftSideSection.items.length === 0 && page.twoRowRightSideSection.items.length === 0) {
            this.template.pages.items.splice(pageIndex, 1);
          }
          break;
        }
      }

    }

    this.selectedItem = null;
    this.selectedItemChange.emit(this.selectedItem);
    this.selectedListItem = null;

    setTimeout(() => this.templateLogicService.splitToPages(this.template));

    this.saveChanges();
  }

  protected onselectSectionSettings($event: Event) {
    $event.stopPropagation();
    this.sectionSettingsSelected = !this.sectionSettingsSelected;
  }

  protected onClickOutsideWorkExpSection() {
    this.sectionSettingsSelected = false;
  }

  protected saveChanges() {
    this.appStateService.saveChanges$.next();
  }

  protected duplicatedSectionExistence(section: TemplateSection) {
    const condition = getTmSectionsByType(this.template, section.type).length > 1;
    return condition;
  }

}
