import {debounceTime, Subject} from "rxjs";
import {TemplateTypes} from "@app/shared/enums";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {TemplateConfigs} from "@app/shared/interfaces";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NzResizableModule, NzResizeEvent} from "ng-zorro-antd/resizable";
import {AppStateService, TemplateLogicService} from "@app/shared/services";
import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sf-tm-resize',
  templateUrl: './tm-resize.component.html',
  styleUrls: ['./tm-resize.component.scss'],
  imports: [
    NzToolTipModule,
    NzResizableModule,
  ],
  standalone: true
})
export class TmResizeComponent implements OnInit {
  private readonly appStateService = inject(AppStateService);
  private readonly templateLogicService = inject(TemplateLogicService);
  private readonly destroyRef = inject(DestroyRef);

  private sidebarId = -1;
  private twoRowId = -1;
  protected readonly TemplateTypes = TemplateTypes;

  @Input({required: true}) public template!: TemplateConfigs;

  private subject$ = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.subject$
      .pipe(
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.templateLogicService.splitTemplateToPages(this.template);
      });
  }

  protected onResizeSidebar({width}: NzResizeEvent): void {
    cancelAnimationFrame(this.sidebarId);
    this.sidebarId = requestAnimationFrame(() => this.template.settings.sidebar.with = Math.round(width!));
    this.saveChanges();
    this.subject$.next();
  }

  protected onResizeTwoRows({width}: NzResizeEvent) {
    cancelAnimationFrame(this.twoRowId);
    this.twoRowId = requestAnimationFrame(() => this.template.settings.twoRows.with = Math.round(width!));
    this.saveChanges();
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

}

