<ng-container [ngSwitch]="template.templateTopPartType">
  <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="Type_1_Ref"/>
  <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="Type_2_Ref"/>
  <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="Type_3_Ref"/>
  <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="Type_4_Ref"/>
  <ng-container *ngSwitchCase="5" [ngTemplateOutlet]="Type_5_Ref"/>
  <ng-container *ngSwitchCase="6" [ngTemplateOutlet]="Type_6_Ref"/>
  <ng-container *ngSwitchCase="7" [ngTemplateOutlet]="Type_7_Ref"/>
</ng-container>

<ng-template #Type_1_Ref>
  <div class="tm-top-wrapper tm-top-wrapper-type-1">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>
        <ng-container [ngTemplateOutlet]="TmTitleRef"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>

    <ng-container [ngTemplateOutlet]="TmSummaryRef"/>
  </div>
</ng-template>

<ng-template #Type_2_Ref>
  <div class="tm-top-wrapper tm-top-wrapper-type-2">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <ng-container [ngTemplateOutlet]="TmTitleRef"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>

    <ng-container [ngTemplateOutlet]="TmSummaryRef"/>
  </div>
</ng-template>

<ng-template #Type_3_Ref>
  <div
    class="tm-top-wrapper tm-top-wrapper-type-3 {{template.summary.state ? '' : 'pb-0'}}"
    [ngStyle]="{
       backgroundColor: tmTheme.topPartBgColor,
    }">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="TmTitleRef"/>

        <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
      </div>

      <ng-container [ngTemplateOutlet]="ImgRef"/>
    </div>

    @if (template.summary.state) {
      <div class="sf-line"></div>

      <ng-container [ngTemplateOutlet]="TmSummaryRef"/>
    }
  </div>
</ng-template>

<ng-template #Type_4_Ref>
  <div class="tm-top-wrapper tm-top-wrapper-type-4">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <div class="w-full">
          <ng-container [ngTemplateOutlet]="TmTitleRef"/>
          <sf-tm-summary
            [template]="template"
            [tmTheme]="tmTheme"
            [selectedItem]="selectedItem"
            (selectedItemChange)="onSelect($event)"
            [hasUnderLine]="false"
          />
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>
  </div>
</ng-template>

<ng-template #Type_5_Ref>
  <div class="tm-top-wrapper tm-top-wrapper-type-5">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <div class="w-full">
          <ng-container [ngTemplateOutlet]="TmTitleRef"/>
          <sf-tm-summary
            [template]="template"
            [tmTheme]="tmTheme"
            [selectedItem]="selectedItem"
            (selectedItemChange)="onSelect($event)"
            [hasUnderLine]="false"
          />
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>
  </div>
</ng-template>

<ng-template #Type_6_Ref>
  <div class="tm-top-wrapper tm-top-wrapper-type-6">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <div class="w-full">
          <ng-container [ngTemplateOutlet]="TmTitleRef"/>
          <sf-tm-summary
            [template]="template"
            [tmTheme]="tmTheme"
            [selectedItem]="selectedItem"
            (selectedItemChange)="onSelect($event)"
            [hasUnderLine]="false"
          />
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef"/>
    </div>
  </div>
</ng-template>

<ng-template #Type_7_Ref>

  <div class="tm-top-wrapper tm-top-wrapper-type-7">
    <div class="social-name-img-wr">
      <div class="top-left-side">
        <ng-container [ngTemplateOutlet]="ImgRef"/>

        <ng-container [ngTemplateOutlet]="TmTitleRef"/>
      </div>

      <ng-container [ngTemplateOutlet]="SocialInfoRef2"/>
    </div>

    <ng-container [ngTemplateOutlet]="SocialInfoRef3"/>

    <ng-container [ngTemplateOutlet]="TmSummaryRef"/>
  </div>
</ng-template>

<ng-template #SocialInfoRef>
  <sf-tm-social-info [template]="template" [tmTheme]="tmTheme" [type]="5"/>
</ng-template>

<ng-template #SocialInfoRef2>
  <sf-tm-social-info [template]="template" [tmTheme]="tmTheme" [type]="6"/>
</ng-template>

<ng-template #SocialInfoRef3>
  <sf-tm-social-info [template]="template" [tmTheme]="tmTheme" [type]="7"/>
</ng-template>

<ng-template #ImgRef>
  @if (template.img.state) {
    @if (!forPrint || (forPrint && (appStateService.user?.image || template.img.src))) {
      <sf-template-img [stroke]="tmTheme.emptyImgColor" [template]="template"/>
    }
  }
</ng-template>

<ng-template #TmTitleRef>
  <sf-tm-name-part
    [template]="template"
    [tmTheme]="tmTheme"
    [selectedItem]="selectedItem"
    (selectedItemChange)="onSelect($event)"/>
</ng-template>

<ng-template #TmSummaryRef>
  <sf-tm-summary
    [template]="template"
    [tmTheme]="tmTheme"
    [selectedItem]="selectedItem"
    (selectedItemChange)="onSelect($event)"/>
</ng-template>
