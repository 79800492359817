<div class="tm-skeleton">
  <nz-skeleton [nzAvatar]="true" [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
  <nz-skeleton [nzActive]="true"></nz-skeleton>
</div>

