<div class="user-delete-main-container">

  <form [formGroup]="deleteUserForm" #form="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
    <div class="delete-part-container" *ngIf="step === 1" formGroupName="reason">

      <div class="top-part">
        <h2>{{ 'delete_user_modal.why_leave' | translate }}</h2>
        <p>{{ 'delete_user_modal.your_feedback' | translate }}</p>
      </div>

      <nz-divider/>

      <div class="checkboxes-part">
        <p class="mb-20">{{ 'delete_user_modal.why_leave' | translate }}</p>

        <div class="checkboxes">
          <div class="sf-checkbox">
            <input type="checkbox" id="too-expensive" formControlName="tooExpensive">
            <label for="too-expensive">{{ 'delete_user_modal.too_expensive' | translate }}</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="features-need" formControlName="missingFeatures">
            <label for="features-need">{{ 'delete_user_modal.missing_features' | translate }}</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="hard-to-use" formControlName="hardToLearn">
            <label for="hard-to-use">{{ 'delete_user_modal.hard_to_use' | translate }}</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="need-not" formControlName="doNotNeed">
            <label for="need-not">{{ 'delete_user_modal.do_not_need' | translate }}</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="other-reason" formControlName="other">
            <label for="other-reason">{{ 'delete_user_modal.other' | translate }}</label>
          </div>
        </div>
      </div>

      <div class="textarea-part">
        <p class="mb-4">{{ 'delete_user_modal.anything_else' | translate }}</p>

        <textarea
          [class.disabled]="!deleteUserForm.controls.reason.controls['other'].value"
          [readOnly]="!deleteUserForm.controls.reason.controls['other'].value"
          class="sf-textarea"
          [autofocus]="false"
          [placeholder]="'placeholder.we_read_every_message' | translate"
          formControlName="otherMessage"
        ></textarea>
      </div>

      <nz-divider></nz-divider>

      <div class="buttons-part">
        <button type="button" class="sf-btn-tertiary small mr-16 w-100" (click)="onCloseModal()">Cancel</button>
        <button
          type="button" class="sf-btn-primary small w-80"
          (click)="step = 2"
          [disabled]="deleteUserForm.controls.reason.invalid">
          {{ 'delete_user_modal.next' | translate }}
        </button>
      </div>

    </div>

    @if (step === 2) {
      <div class="delete-part-container">

        <div class="top-part">
          <h2>{{ 'delete_user_modal.was_not_bad' | translate }}</h2>
          <p>{{ 'delete_user_modal.feedback_appreciated' | translate }}</p>
        </div>

        <nz-divider/>

        <ng-container formGroupName="advantages">
          <div class="checkboxes-part">
            <p class="mb-20">{{ 'delete_user_modal.what_we_do_well' | translate }}</p>

            <div class="checkboxes">
              <div class="sf-checkbox">
                <input type="checkbox" id="good-price" formControlName="goodPrice">
                <label for="good-price">{{ 'delete_user_modal.affordable_price' | translate }}</label>
              </div>

              <div class="sf-checkbox">
                <input type="checkbox" id="helpful-support" formControlName="helpfulSupport">
                <label for="helpful-support">{{ 'delete_user_modal.helpful_support' | translate }}</label>
              </div>

              <div class="sf-checkbox">
                <input type="checkbox" id="easy-to-use" formControlName="easyToUse">
                <label for="easy-to-use">{{ 'delete_user_modal.easy_use' | translate }}</label>
              </div>

              <div class="sf-checkbox">
                <input type="checkbox" id="other-reason2" formControlName="other">
                <label for="other-reason2">{{ 'delete_user_modal.other' | translate }}</label>
              </div>
            </div>
          </div>

          <div class="textarea-part">
            <p>{{ 'delete_user_modal.share_with_team' | translate }}</p>

            <textarea
              [class.disabled]="!deleteUserForm.controls.advantages.controls['other'].value"
              [readOnly]="!deleteUserForm.controls.advantages.controls['other'].value"
              class="sf-textarea"
              [placeholder]="'placeholder.we_read_every_message' | translate"
              formControlName="otherMessage"
            ></textarea>
          </div>
        </ng-container>

        <div class="sf-input-wr mt-40">
          <div class="sf-checkbox" [class.error]="form.submitted && deleteUserForm.controls.areYouSure.errors">
            <input type="checkbox" id="are-you-sure" formControlName="areYouSure">
            <label for="are-you-sure">{{ 'delete_user_modal.delete_permanently' | translate }}</label>
          </div>

          <div class="sf-err-wr" *ngIf="form.submitted && deleteUserForm.controls.areYouSure.errors as error">
            <div class="sf-input-err" *ngIf="error['required']">
              <i class="ph ph-warning"></i>
              {{ 'validation.field_required' | translate }}
            </div>
          </div>
        </div>

        <nz-divider/>

        <div class="buttons-part">
          <button type="button" class="sf-btn-tertiary small mr-16 w-100" (click)="step = 1">
            {{ 'delete_user_modal.back' | translate }}
          </button>

          <button
            class="sf-btn-delete small w-220"
            [disabled]="deleteUserForm.controls.advantages.invalid"
            [class.loading]="deleteBtnLoadingState">
            {{ 'delete_user_modal.delete_account' | translate }}
          </button>
        </div>

      </div>
    }

  </form>

</div>
