import {NgStyle} from "@angular/common";
import {TmSettings} from "@app/shared/types";
import {NzMessageService} from "ng-zorro-antd/message";
import {TemplateTypes} from "@app/shared/enums";
import {ContentEditableMaxLengthDirective} from "@app/shared/directives";
import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {AppStateService, TemplateLogicService, TemplateService} from "@app/shared/services";
import {CoverLetterSettingsThemeColor, TemplateConfigs, TemplateSettingsThemeColor} from "@app/shared/interfaces";

@Component({
  selector: 'sf-tm-name-part',
  templateUrl: './tm-name-part.component.html',
  styleUrls: ['./tm-name-part.component.scss'],
  imports: [
    NgStyle,
    ContentEditableMaxLengthDirective,
  ],
  standalone: true
})
export class TmNamePartComponent {
  public readonly appStateService = inject(AppStateService);
  public readonly templateService = inject(TemplateService);
  public readonly nzMessageService = inject(NzMessageService);
  public readonly templateLogicService = inject(TemplateLogicService);

  @Input({required: true}) public template!: TmSettings;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor | CoverLetterSettingsThemeColor;
  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter<any>();

  @Input() public insideSidebar = false;

  private focusedElText = '';

  constructor() {
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
    this.selectedItemChange.emit(this.selectedItem);
  }

  protected onBlurTmMainTitle($event: FocusEvent) {
    this.template.title.text = ($event.target as any).innerHTML;

    if (this.focusedElText !== this.template.title.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onBlurTmSubTitle($event: FocusEvent) {
    this.template.subTitle.text = ($event.target as any).innerHTML;

    if (this.focusedElText !== this.template.subTitle.text) {
      this.saveChanges();
      this.focusedElText = '';
    }
  }

  protected onFocusEL($event: FocusEvent, text: string) {
    this.focusedElText = text;
  }

  protected handleTitleEnter($event: any) {
    if ((this.template as TemplateConfigs).type !== TemplateTypes.SIDEBAR) {
      $event.preventDefault();
    }
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }
}

