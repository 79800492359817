import {cloneObject} from "@app/shared/helpers";
import {NzIconModule} from "ng-zorro-antd/icon";
import {fadeInOut} from "@app/shared/constants";
import {NZ_MODAL_DATA} from "ng-zorro-antd/modal";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {ClickOutsideDirective} from "@app/shared/directives";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {PageTypePipe, SectionTypePipe, TmThemeTypePipe} from "@app/shared/pipes";
import {AppStateService, TemplateLogicService, TemplateService} from "@app/shared/services";
import {NgForOf, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgTemplateOutlet} from "@angular/common";
import {SectionNames, TemplateSectionTypes, TemplateTypes, TmClassnames, TmSocialInfos} from "@app/shared/enums";
import {SocialInfoSidebarComponent} from "@app/templates/componenets/social-info-sidebar/social-info-sidebar.component";
import {
  AllTemplatesThemeColor,
  SingleTemplateThemeColor,
  Template,
  TemplateConfigs,
  TemplateSettingsThemeColor
} from "@app/shared/interfaces";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Inject,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  BrandingComponent,
  EmptySectionComponent,
  ListSectionListComponent,
  SkeletonComponent,
  SocialInfoSettingsComponent,
  TemplateImgComponent,
  Tm5SidebarTopPartComponent,
  TmNamePartComponent,
  TmResizeComponent,
  TmSidebarTopPartComponent,
  TmSocialInfoComponent,
  TmSummaryComponent,
  TmTopPartComponent,
  WorkExpSectionListComponent
} from "@app/templates/componenets";
import {TranslateModule} from "@ngx-translate/core";

interface ModalData {
  template: Template<TemplateConfigs>;
  forPreview: boolean;
}

@Component({
  selector: 'sf-cv-template',
  templateUrl: './cv-template.component.html',
  styleUrls: ['./cv-template.component.scss'],
  animations: [fadeInOut],
  imports: [
    NgStyle,
    ClickOutsideDirective,
    NgIf,
    TmResizeComponent,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    PageTypePipe,
    TmNamePartComponent,
    TmSocialInfoComponent,
    SectionTypePipe,
    WorkExpSectionListComponent,
    ListSectionListComponent,
    TemplateImgComponent,
    NzIconModule,
    EmptySectionComponent,
    BrandingComponent,
    SkeletonComponent,
    NzToolTipModule,
    SocialInfoSidebarComponent,
    TmTopPartComponent,
    TmSidebarTopPartComponent,
    TmSummaryComponent,
    TmThemeTypePipe,
    Tm5SidebarTopPartComponent,
    TranslateModule
  ],
  standalone: true,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvTemplateComponent implements OnChanges, OnInit, AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly elementRef = inject(ElementRef);
  private readonly nzDrawerService = inject(NzDrawerService);
  protected readonly appStateService = inject(AppStateService);
  protected readonly templateService = inject(TemplateService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly templateLogicService = inject(TemplateLogicService);

  @Input() enableChangeDetection = true;
  @Input() firstPageOnly = false;
  @Input() public forPreview = false;
  @Input() public forPrint = false;
  @Input({required: true}) public template!: Template<TemplateConfigs>;

  @ViewChild('DrawerTitleRef', {static: false}) private drawerTitleRef?: TemplateRef<HTMLDivElement>;

  @Output() protected openRearrange$ = new EventEmitter<void>();
  protected readonly TemplateSectionTypes = TemplateSectionTypes;

  protected selectedItem: any = null;
  protected selectedListItem: any = null;

  protected readonly TemplateTypes = TemplateTypes;

  protected readonly SectionNames = SectionNames;

  private socialInfoDrawer: NzDrawerRef<SocialInfoSettingsComponent> | null = null;

  protected tmTheme: TemplateSettingsThemeColor = {} as TemplateSettingsThemeColor;

  protected readonly TmClassnames = TmClassnames;
  protected window = window;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: ModalData
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['template']?.currentValue) {
      this.tmTheme = this.getTemplateTheme();
      this.appStateService.setTemplateCurrentFontSize(this.template.settings.settings.font.size.name);
    }
  }

  ngOnInit() {
    if (this.modalData) {
      this.forPreview = this.modalData.forPreview;
      this.template = this.modalData.template;
    }

    this.handle_backward_capability();

    this.tmTheme = this.getTemplateTheme();
    this.appStateService.setTemplateCurrentFontSize(this.template.settings.settings.font.size.name);

    this.listenToTmThemeChanges();
  }

  ngAfterViewInit() {
    if (!this.enableChangeDetection) {
      this.changeDetectorRef.detach(); // Detach change detector to stop change detection
    } else {
      this.changeDetectorRef.reattach(); // Manually trigger change detection
    }

    this.handleForPrintCase();
  }

  private listenToTmThemeChanges() {
    this.appStateService.templateThemeChanged$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.tmTheme = this.getTemplateTheme();
      });
  }

  private handleForPrintCase() {
    if (this.forPrint) {
      console.log('forPrint', this.forPrint);
      console.log('template', this.template);
      console.log('elementRef', this.elementRef);

      document.body.style.margin = '0';
      document.documentElement.style.margin = '0';

      document.body.style.padding = '0';
      document.documentElement.style.padding = '0';

      document.body.style.height = 'initial';
      document.body.style.minHeight = 'initial';

      document.body.style.width = 'initial';
      document.body.style.minWidth = 'initial';

      document.documentElement.style.height = 'initial';
      document.documentElement.style.minHeight = 'initial';

      document.documentElement.style.width = 'initial';
      document.documentElement.style.minWidth = 'initial';

      const templateMainContainer: HTMLDivElement = this.elementRef.nativeElement.firstChild;

      const tmTopWrapper: HTMLDivElement | null = templateMainContainer.querySelector('.tm-top-wrapper');

      if (tmTopWrapper) {
        tmTopWrapper.style.borderRadius = '0';
      }

      // templateMainContainer.style.pointerEvents = 'none';
      templateMainContainer.style.margin = '0';
      templateMainContainer.style.marginBottom = '-1px';
      templateMainContainer.style.overflow = 'hidden';
      templateMainContainer.style.borderRadius = '0';

      const tmPageParentEl = templateMainContainer.querySelectorAll(`.${TmClassnames.TEMPLATE_PAGE_PARENT}`);

      tmPageParentEl.forEach((item) => {

        const pageParentEL = item as HTMLDivElement;
        pageParentEL.style.boxShadow = 'none';

        const sidebarEL = pageParentEL.querySelector('.left-side') as HTMLDivElement;

        if (sidebarEL) {
          sidebarEL.style.borderRadius = '0';
        }
      });
    }
  }

  private getTemplateTheme() {
    const templateId = this.template.templateId || 1;
    return this.appStateService.templateThemeColor['template' + templateId as keyof AllTemplatesThemeColor]?.['theme' + this.template?.settings?.settings?.theme?.colorId as keyof SingleTemplateThemeColor];
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
  }

  protected onOverlayClick() {
    this.selectedItem = null;
    this.selectedListItem = null;
    this.templateLogicService.splitToPages(this.template.settings);
    console.log('this.template', this.template);
  }

  protected onClickOutSide() {
    this.selectedItem = null;
    this.selectedListItem = null;
    if (!this.forPreview) {
      this.templateLogicService.splitToPages(this.template.settings);
    }
  }

  protected onAddSection() {
    this.openRearrange$.emit();
  }

  protected onSidebarSwipe() {
    this.template.settings.sidebarPosition = (this.template.settings.sidebarPosition === 'left') ? 'right' : 'left';
    this.saveChanges();
  }

  private saveChanges() {
    this.appStateService.saveChanges$.next();
  }

  /** Social Info Start */

  protected openSocialInfoSettings() {
    this.socialInfoDrawer = this.nzDrawerService.create({
      nzTitle: this.drawerTitleRef,
      nzPlacement: 'left',
      nzContent: SocialInfoSettingsComponent,
      nzClosable: false,
      nzContentParams: {
        template: this.template.settings
      }
    });
  }

  protected onCloseSidebar() {
    this.socialInfoDrawer?.close();
  }

  /** Social Info End */

  /** Backward Capability */

  private handle_backward_capability() {
    if (!this.template.settings.settings.theme.colorId) {
      this.template.settings.settings.theme.colorId = 1;
    }

    const socialTelegram = this.template.settings.socialInfo.items
      .some((social) => social.type === TmSocialInfos.TELEGRAM);

    if (!socialTelegram) {
      this.template.settings.socialInfo.items.push(
        cloneObject({
          type: TmSocialInfos.TELEGRAM,
          icon: 'ph ph-telegram-logo',
          text: '',
          pl: '@johnDoe',
        })
      );
    }

  }

}

