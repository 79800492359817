<div class="tm-st-banner" *ngIf="(selectedItem === sectionItem) && !appStateService.editorBannerState">

  <div class="new-entry" (click)="onAddNewEntry(section, sectionItemIndex, WorkExperienceListRef)">
    <i class="ph ph-plus-circle"></i>
    New Entry
  </div>

  <i class="ph ph-caret-down" *ngIf="!last" (click)="onMoveDown(section, sectionItemIndex, WorkExperienceListRef)"></i>
  <i class="ph ph-caret-up" *ngIf="!first" (click)="onMoveUp(section, sectionItemIndex, WorkExperienceListRef)"></i>

<!--  <i class="ph ph-lightbulb-filament"></i>-->
  <i class="ph ph-gear" [class.active]="sectionSettingsSelected" (click)="onselectSectionSettings($event)"></i>

  @if (section.items.length > 1 || duplicatedSectionExistence(section)) {
    <i
      class="ph ph-trash-simple"
      (click)="onRemoveSectionItem(section, sectionItemIndex)"
    ></i>
  } @else {
    <i
      class="ph ph-trash-simple"
      nz-popconfirm="Are you sure delete this section?"
      (nzOnConfirm)="onRemoveLastSectionItem(pageIndex, sectionIndex, sectionName)"
    ></i>
  }

  <div
    class="tm-st-banner-settings work-exp"
    *ngIf="sectionSettingsSelected"
    [sfCapture]="true"
    (sfClickOutside)="onClickOutsideWorkExpSection()">
    <div class="checkboxes-container">
      <div class="sf-checkbox pink" *ngFor="let item of sectionItem | workExpSectionSt">
        <input
          type="checkbox"
          [id]="'set-checkbox' + item.keyName"
          [(ngModel)]="sectionItem[item.keyName].state"
          (ngModelChange)="saveChanges()">
        <label [for]="'set-checkbox' + item.keyName">{{ item.name }}</label>
      </div>
    </div>

    <div class="ach-cols-count" *ngIf="sectionItem.achievements?.column && sectionItem.achievements?.state">
      <div class="mb-8">Columns counts</div>

      <div class="cols-count-list">
        @for (count of achColCounts; track count.count) {
          <i
            class="{{count.className}}"
            (click)="onChangeAchColumnsCount(sectionItem, count)"
            [class.active]="sectionItem.achievements?.column?.cols === count.count"
          ></i>
        }
      </div>
    </div>
  </div>

</div>

