<div class="name-part">
  <h1
    id="introjs-main-title"
    contenteditable="true"
    [innerHTML]="template.title.text"
    [attr.placeholder]="template.title.pl"
    [class.edit-mode]="selectedItem === template"
    (click)="onSelect(template)"
    (keydown.enter)="handleTitleEnter($event)"
    [sfContentEditableMaxLength]="appStateService.textMaxSizes.mainTitle"
    (blur)="onBlurTmMainTitle($event)"
    (focus)="onFocusEL($event, template.title.text)"
    [ngStyle]="{
        fontSize: appStateService.templateCurrentFontSize.mainTitle + 'px',
        lineHeight: appStateService.templateCurrentFontSize.mainTitleLineHeight + 'px',
        color: insideSidebar ? '#303030' : (selectedItem === template ? tmTheme.mainTitleFontEditModeColor : tmTheme.mainTitleFontColor),
    }"
  ></h1>

  @if (template.subTitle.state) {
    <h3
      contenteditable="true"
      [attr.placeholder]="template.subTitle.pl"
      [class.edit-mode]="selectedItem === template.subTitle"
      (click)="onSelect(template.subTitle)"
      [sfContentEditableMaxLength]="appStateService.textMaxSizes.suTitle"
      [innerHTML]="template.subTitle.text"
      (blur)="onBlurTmSubTitle($event)"
      (focus)="onFocusEL($event, template.subTitle.text)"
      (keydown.enter)="$event.preventDefault()"
      [ngStyle]="{
         fontSize: appStateService.templateCurrentFontSize.mainSubtitle + 'px',
         lineHeight: appStateService.templateCurrentFontSize.mainSubtitleLineHeight + 'px',
         color: insideSidebar ? '#303030' : (selectedItem === template.subTitle ? tmTheme.subTitleFontEditModeColor : tmTheme.subTitleFontColor),
         backgroundColor: tmTheme.subTitleBGColor
      }"
    ></h3>
  }
</div>
