<div class="social-info-sidebar-settings-wr">

  @if ($any(template).type === TemplateTypes.SIDEBAR) {
    <div class="move-to-sidebar">
      <div (click)="toggleSidebarPosition()">Move to sidebar</div>
      <nz-switch
        [(ngModel)]="template.socialInfo.onSidebar"
        (ngModelChange)="saveChanges()"
      ></nz-switch>
    </div>
  }

  @if ($any(template).type === TemplateTypes.SIDEBAR && template.socialInfo.onSidebar) {
    @if (!template.pureTemplate) {
      <div class="move-to-sidebar">
        <div (click)="toggleSidebarTitle()">Section Title Enabled</div>
        <nz-switch
          [(ngModel)]="template.socialInfo.title.state"
          (ngModelChange)="saveChanges()"
        ></nz-switch>
      </div>
    }
  }

  <div class="social-info-container" cdkDropList (cdkDropListDropped)="onMoveSocialInfo($event)">
    @for (item of template.socialInfo.items; track item.type) {
      <div class="tm-social-info-item-drg" cdkDrag>
        <i
          class="ph ph-dots-six-vertical"
          cdkDragHandle
          [nz-tooltip]="'tooltips.drag_item' | translate"
        ></i>

        <div class="sf-input2-wr has-prefix large">
          <i class="i-prefix {{item.icon}} d-flex ai-c h-16"></i>

          <input
            type="text"
            class="sf-input2"
            [(ngModel)]="item.text"
            placeholder="{{item.pl}}"
            (ngModelChange)="saveChanges()"/>

          @if (template.pureTemplate) {
            <i
              class="right-i ph {{item.state ? 'ph-eye' : 'ph-eye-slash'}}"
              (click)="onToggleSocialInfoState(item)"
            ></i>
          } @else {
            @if (item.settings) {
              <i
                class="right-i ph ph-gear"
                nz-dropdown
                [nzClickHide]="false"
                [nzDropdownMenu]="menu"
                nzTrigger="click"
              ></i>
            } @else {
              <i
                class="right-i ph {{item.state ? 'ph-eye' : 'ph-eye-slash'}}"
                (click)="onToggleSocialInfoState(item)"
              ></i>
            }

            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu class="tm-social-st">
                <li nz-menu-item (click)="onToggleSocialInfoState(item)">
                  <div class="d-flex ai-c">
                    {{ item.state ? 'Hide' : 'Show' }}
                    <i class="right-i ph ml-6 {{item.state ? 'ph-eye-slash' : 'ph-eye'}}"></i>
                  </div>
                </li>

                <li nz-menu-item (click)="changeSocialLinkShorten(item)">
                  <div class="d-flex ai-c">
                    {{ item.settings?.shorten ? 'Make Long' : 'Make Short' }}
                    <i
                      class="ph ml-6 {{item.settings?.shorten ? 'ph-arrows-out-line-horizontal' : 'ph-arrows-in-line-horizontal'}}"
                    ></i>
                  </div>
                </li>
              </ul>
            </nz-dropdown-menu>
          }

        </div>
      </div>
    }
  </div>
</div>

