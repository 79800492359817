<div class="image-crop-main-container">
  <div class="img-actions">
    <i
      class="ph ph-arrow-counter-clockwise"
      nz-tooltip
      [nzTooltipTitle]="'crop_image_modal.rotate_left' | translate"
      [nzTooltipMouseEnterDelay]="1"
      (click)="rotateLeft()"
    ></i>
    <i
      class="ph ph-arrow-clockwise"
      nz-tooltip
      [nzTooltipTitle]="'crop_image_modal.rotate_right' | translate"
      [nzTooltipMouseEnterDelay]="1"
      (click)="rotateRight()"
    ></i>

    <div class="sf-v-divider"></div>

    <i
      class="ph ph-arrows-horizontal"
      nz-tooltip
      [nzTooltipTitle]="'crop_image_modal.flip_horizontal' | translate"
      [nzTooltipMouseEnterDelay]="1"
      (click)="flipHorizontal()"
    ></i>
    <i
      class="ph ph-arrows-vertical"
      nz-tooltip
      [nzTooltipTitle]="'crop_image_modal.flip_vertical' | translate"
      [nzTooltipMouseEnterDelay]="1"
      (click)="flipVertical()"
    ></i>

    <div class="sf-v-divider"></div>

    <i
      class="ph ph-stop-circle"
      nz-tooltip
      [nzTooltipTitle]="'crop_image_modal.reset_image' | translate"
      [nzTooltipMouseEnterDelay]="1"
      (click)="resetImage()"
    ></i>

    <div class="sf-v-divider"></div>

    <i
      class="ph ph-magnifying-glass-plus"
      nz-tooltip
      [nzTooltipTitle]="'crop_image_modal.zoom_in' | translate"
      [nzTooltipMouseEnterDelay]="1"
      (click)="zoomIn()"
    ></i>

    <i
      class="ph ph-magnifying-glass-minus"
      nz-tooltip
      [nzTooltipTitle]="'crop_image_modal.zoom_out' | translate"
      [nzTooltipMouseEnterDelay]="1"
      (click)="zoomOut()"
    ></i>
  </div>

  <nz-divider/>

  <div>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="false"
      [aspectRatio]="3 / 3"
      [resizeToWidth]="256"
      [cropperMinWidth]="128"
      [onlyScaleDown]="true"
      [roundCropper]="false"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"/>
  </div>

  <nz-divider/>

  <div class="d-flex jc-sb align-e">
    <img [src]="imageCroppedEvent?.objectUrl || ''" style="width: 122px;border-radius: 50%;" alt="selfcv user"/>

    <div class="btn-area">
      <button class="sf-btn-primary small" [class.loading]="loadingState" (click)="onImgSave()">
        {{ 'crop_image_modal.save' | translate }}
      </button>
    </div>
  </div>

</div>
