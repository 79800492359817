<div
  class="empty-section"
  (click)="onAddSection()"
  [class.mt-20]="!firstPage"
  [ngStyle]="{
      backgroundColor: tmTheme.emptySection.bgColor,
    }">
  <button
    class="sf-btn-fifth"
    [ngStyle]="{
        backgroundColor: tmTheme.emptySection.btnBgColor,
        borderColor: tmTheme.emptySection.btnBorderColor,
      }">
    <i class="ph ph-plus-circle"></i>
    Add Section
  </button>
</div>
