import {NzIconModule} from "ng-zorro-antd/icon";
import {AppStateService} from "@app/shared/services";
import {NzDrawerRef, NzDrawerService} from "ng-zorro-antd/drawer";
import {SocialInfoSettingsComponent} from "@app/templates/componenets";
import {NgForOf, NgIf, NgStyle, NgTemplateOutlet} from "@angular/common";
import {TemplateConfigs, TemplateSettingsThemeColor} from "@app/shared/interfaces";
import {Component, inject, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'sf-tm-social-info',
  templateUrl: './tm-social-info.component.html',
  styleUrls: ['./tm-social-info.component.scss'],
  imports: [
    NzIconModule,
    NgStyle,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
  ],
  standalone: true,
})
export class TmSocialInfoComponent implements OnInit {
  private readonly nzDrawerService = inject(NzDrawerService);
  protected readonly appStateService = inject(AppStateService);

  @Input({required: true}) public template!: TemplateConfigs;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;

  @ViewChild('DrawerTitleRef', {static: false}) private drawerTitleRef?: TemplateRef<HTMLDivElement>;
  private socialInfoDrawer: NzDrawerRef<SocialInfoSettingsComponent> | null = null;

  // protected currentFontSize = this.appStateService.fontSizes.find((sizes) => sizes.name == TmFontSizesTypes.MEDIUM) as TemplateSettingsFontSize;

  @Input({required: true}) public type = 1;

  constructor() {
  }

  ngOnInit() {
  }

  /*protected enabledSocialItemsFirst() {
    return this.template.socialInfo.items.filter((item) => item.state)
      .filter((item, index) => index <= 3);
  }

  protected enabledSocialItemsLast() {
    return this.template.socialInfo.items.filter((item) => item.state)
      .filter((item, index) => index > 3);
  }*/

  protected enabledSocialItemsFirst() {
    return this.template.socialInfo.items.filter((item) => item.state).slice(0, 4);
  }

  protected enabledSocialItemsLast() {
    return this.template.socialInfo.items.filter((item) => item.state).slice(4, 15);
  }

  protected openSocialInfoSettings() {
    this.socialInfoDrawer = this.nzDrawerService.create({
      nzTitle: this.drawerTitleRef,
      nzPlacement: 'left',
      nzContent: SocialInfoSettingsComponent,
      nzClosable: false,
      nzContentParams: {
        template: this.template
      }
    });
  }

  protected onCloseSidebar() {
    this.socialInfoDrawer?.close();
  }

}

