import {AppStateService} from "@app/shared/services";
import {TemplateConfigs, TemplateSettingsThemeColor} from "@app/shared/interfaces";
import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TmSummaryComponent} from "@app/templates/componenets/tm-summary/tm-summary.component";
import {NgForOf, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgTemplateOutlet} from "@angular/common";
import {TmNamePartComponent} from "@app/templates/componenets/tm-name-part/tm-name-part.component";
import {TemplateImgComponent} from "@app/templates/componenets/template-img/template-img.component";
import {TmSocialInfoComponent} from "@app/templates/componenets/tm-social-info/tm-social-info.component";

@Component({
  selector: 'sf-tm-sidebar-top-part',
  templateUrl: './tm-sidebar-top-part.component.html',
  styleUrls: ['./tm-sidebar-top-part.component.scss'],
  imports: [
    TemplateImgComponent,
    TmNamePartComponent,
    TmSocialInfoComponent,
    NgForOf,
    NgIf,
    TmSummaryComponent,
    NgTemplateOutlet,
    NgSwitchCase,
    NgSwitch,
    NgStyle
  ],
  standalone: true
})
export class TmSidebarTopPartComponent implements OnInit {
  protected appStateService = inject(AppStateService);

  @Input({required: true}) public template!: TemplateConfigs;
  @Input({required: true}) public tmTheme!: TemplateSettingsThemeColor;
  @Input({required: true}) public forPrint!: boolean;

  @Input({required: true}) public selectedItem: any = null;
  @Output() public selectedItemChange = new EventEmitter();


  constructor() {
  }

  ngOnInit() {
  }

  protected onSelect(item: any) {
    this.selectedItem = item;
    this.selectedItemChange.emit(this.selectedItem);
  }

}

