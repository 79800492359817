<div class="template-settings-sidebar-container">

  <div class="header-parts d-flex ai-c jc-sb">
    <div class="sf-checkbox min-w-80">
      <input
        type="checkbox"
        id="tm-title"
        [(ngModel)]="template.settings.subTitle.state"
        (ngModelChange)="saveChanges()">
      <label for="tm-title">{{'builder.title' | translate}}</label>
    </div>

    <div class="sf-checkbox min-w-80">
      <input type="checkbox" id="tm-photo" [(ngModel)]="template.settings.img.state" (ngModelChange)="saveChanges()">
      <label for="tm-photo">{{'builder.photo' | translate}}</label>
    </div>

    <div class="sf-checkbox min-w-80">
      @if (template.documentType === DocumentTypes.CV) {
        <input
          type="checkbox"
          id="tm-summary"
          [(ngModel)]="$any(template.settings).summary.state"
          (ngModelChange)="saveChanges()">
        <label for="tm-summary">{{'builder.summary' | translate}}</label>
      }
    </div>
  </div>

  <nz-divider/>

  @if (template.documentType === DocumentTypes.CV) {
    <sf-tm-layouts [template]="$any(template).settings"/>

    <nz-divider/>
  }

  @if ($any(template.settings).type === TemplateTypes.SIDEBAR) {
    <div class="move-to-sidebar">
      <div (click)="toggleSocialInfoSidebarPosition()">
        {{'builder.move_social_info_into_sidebar' | translate}}
      </div>
      <nz-switch
        [(ngModel)]="template.settings.socialInfo.onSidebar"
        (ngModelChange)="saveChanges()"/>
    </div>
  }

  @if (!template.settings.pureTemplate) {
    @if (template.settings.socialInfo.onSidebar) {
      @if ($any(template.settings).type === TemplateTypes.SIDEBAR) {
        <div class="move-to-sidebar">
          <div (click)="onToggleSocialInfoTitleState()">
            {{'builder.social_info_title_enabled' | translate}}
          </div>
          <nz-switch
            [(ngModel)]="template.settings.socialInfo.title.state"
            (ngModelChange)="saveChanges()"/>
        </div>
      }
    }

    @if (template.documentType === DocumentTypes.CV) {
      <div class="move-to-sidebar">
        <div (click)="onToggleSummaryTitleState()">
          {{'builder.summary_title_enabled' | translate}}
        </div>
        <nz-switch
          [(ngModel)]="$any(template.settings).summary.title.state"
          (ngModelChange)="saveChanges()"/>
      </div>
    }
  }

  @if (template.documentType === DocumentTypes.COVER) {
    <div class="d-flex align-c jc-sb mt-20">
      <div class="c-pointer" (click)="toggleCoverRecipient()">
        {{'builder.cover_letter_recipient' | translate}}
      </div>
      <nz-switch
        [(ngModel)]="$any(template.settings).companyDetails.state"
        (ngModelChange)="coverRecipientStateChanged()"/>
    </div>

    <div class="d-flex align-c jc-sb mt-20">
      <div class="c-pointer" (click)="toggleCoverSender()">
        {{'builder.cover_letter_sender' | translate}}
      </div>
      <nz-switch
        [(ngModel)]="$any(template.settings).content.sender.state"
        (ngModelChange)="saveChanges()"/>
    </div>
  }

  @if (template.documentType === DocumentTypes.CV) {
    @if ($any(template.settings).type === TemplateTypes.SIDEBAR) {

      <nz-divider/>

      <div>
        <div class="fs-13 mb-4">{{'builder.sidebar_position' | translate}}</div>
        <nz-segmented
          [nzOptions]="sidebarOptions"
          [ngModel]="$any(template.settings).sidebarPosition === 'left' ? 0 : 1"
          (nzValueChange)="handleIndexChange($event)"/>
      </div>

      <nz-divider/>

      <div>
        <div class="fs-13 mb-4">{{'builder.sidebar_width' | translate}}</div>
        <nz-slider
          [nzMarks]="marks"
          [nzMin]="$any(template.settings).settings.sidebar.minWidth"
          [nzMax]="$any(template.settings).settings.sidebar.maxWidth"
          [(ngModel)]="$any(template.settings).settings.sidebar.with"
          (ngModelChange)="saveChanges()"/>
      </div>
    }

  }

  <nz-divider/>

  <div class="d-flex align-c jc-sb">
    <div class="c-pointer d-flex ai-c" (click)="toggleBranding()">
      <i
        class="ph ph-star mr-6 color-4b57dc"
        [nz-tooltip]="'tooltips.paid' | translate"
        [nzTooltipMouseEnterDelay]="1"
      ></i>
      {{'builder.branding' | translate}}
    </div>

    <nz-switch
      [(ngModel)]="template.settings!.branding.enabled"
      (ngModelChange)="brandingStateChanged()"/>
  </div>

</div>
