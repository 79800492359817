@switch (type) {
  @case (1) {
    <!-- On Sidebar right side, top parts, split -->
    <div class="social-info case-1" (click)="openSocialInfoSettings()">
      <ul>

        <!--
        [ngStyle]="{
          backgroundColor: tmTheme.socialInfo.bgColor,
          color: tmTheme.socialInfo.fontColor,
        }"
        -->

        @for (item of enabledSocialItemsFirst(); track $index) {
          <li
            [ngStyle]="{
           fontSize: appStateService.templateCurrentFontSize.socialInfo + 'px',
           lineHeight: appStateService.templateCurrentFontSize.socialInfoLineHeight + 'px',
        }">
            <i class="{{item.icon}}"></i>
            <ng-container [ngTemplateOutlet]="ContentPartRef" [ngTemplateOutletContext]="{item}"/>
          </li>
        }
      </ul>
    </div>
  }
  @case (2) {
    <!-- On Sidebar right side, bottom parts, split -->
    <div
      class="tm-social-infos case-2"
      *ngIf="!template.socialInfo.onSidebar && enabledSocialItemsLast().length"
      (click)="openSocialInfoSettings()">
      <ul>
        @for (item of enabledSocialItemsLast(); track item.pl) {
          <li>
            <i class="{{item.icon}}"></i>
            <ng-container [ngTemplateOutlet]="ContentPartRef" [ngTemplateOutletContext]="{item}"/>
          </li>
        }
      </ul>
    </div>
  }
  @case (3) {
    <!-- On Sidebar, tm-id=5 (modified top part) -->
    <div
      class="social-info on-sidebar-right-side-top case-3"
      (click)="openSocialInfoSettings()"
      [ngStyle]="{
        fontSize: appStateService.templateCurrentFontSize.socialInfo + 'px',
        lineHeight: appStateService.templateCurrentFontSize.socialInfoLineHeight + 'px',
        color: tmTheme.socialInfo.fontColor,
        backgroundColor: tmTheme.socialInfo.bgColor,
        borderColor: tmTheme.socialInfo.borderColor
      }">
      <ul>
        @for (item of template.socialInfo.items; track item.pl) {
          <li *ngIf="item.state">
            <i class="{{item.icon}}"></i>
            <ng-container [ngTemplateOutlet]="ContentPartRef" [ngTemplateOutletContext]="{item}"/>
          </li>
        }
      </ul>
    </div>
  }
  @case (4) {
    <!-- On Sidebar, bottom part, not split -->
    <div
      class="tm-social-infos case-4"
      (click)="openSocialInfoSettings()"
      [ngStyle]="{
        backgroundColor: tmTheme.socialInfo.bgColor,
        color: tmTheme.socialInfo.fontColor,
      }">
      <ul>
        @for (item of template.socialInfo.items; track item.pl) {
          <li class="d-flex ai-c" *ngIf="item.state">
            <i class="{{item.icon}}"></i>
            <ng-container [ngTemplateOutlet]="ContentPartRef" [ngTemplateOutletContext]="{item}"/>
          </li>
        }
      </ul>
    </div>
  }
  @case (5) {
    <!-- Non Sidebar, top part, not split -->
    <div
      class="social-info case-5"
      (click)="openSocialInfoSettings()"
      [ngStyle]="{
        backgroundColor: tmTheme.socialInfo.bgColor,
        color: tmTheme.socialInfo.fontColor,
      }">
      <ul>
        <ng-container *ngFor="let item of template.socialInfo.items">
          <li *ngIf="item.state">
            <i class="{{item.icon}}"></i>
            <ng-container [ngTemplateOutlet]="ContentPartRef" [ngTemplateOutletContext]="{item}"/>
          </li>
        </ng-container>
      </ul>
    </div>
  }
  @case (6) {
    <!-- Non Sidebar, top part, split -->
    <div
      class="social-info case-6"
      *ngIf="enabledSocialItemsFirst().length"
      (click)="openSocialInfoSettings()"
      [ngStyle]="{
        color: tmTheme.socialInfo.fontColor,
      }">
      <ul>
        <ng-container *ngFor="let item of enabledSocialItemsFirst()">
          <li>
            <i class="{{item.icon}}"></i>
            <ng-container [ngTemplateOutlet]="ContentPartRef" [ngTemplateOutletContext]="{item}"/>
          </li>
        </ng-container>
      </ul>
    </div>
  }
  @case (7) {
    <!-- Non Sidebar, bottom part, split -->
    <div
      class="social-info mb-10 mt-0 case-7"
      *ngIf="enabledSocialItemsLast().length"
      (click)="openSocialInfoSettings()"
      [ngStyle]="{
        backgroundColor: tmTheme.socialInfo.bgColor,
        color: tmTheme.socialInfo.fontColor,
      }">
      <ul>
        <ng-container *ngFor="let item of enabledSocialItemsLast()">
          <li *ngIf="item.state">
            <i class="{{item.icon}}"></i>

            <ng-container [ngTemplateOutlet]="ContentPartRef" [ngTemplateOutletContext]="{item}"/>
          </li>
        </ng-container>
      </ul>
    </div>
  }
}

<ng-template #ContentPartRef let-item=item>
  @if (item.link && item.text) {

    @if (appStateService.onPrintPage) {
      <a [href]="item.text" target="_blank" rel="noopener">
        {{ (item?.settings?.shorten ? item.settings!.shortenName : item.text) || item.pl }}
      </a>
    } @else {
      <a>
        {{ (item?.settings?.shorten ? item.settings!.shortenName : item.text) || item.pl }}
      </a>
    }

  } @else {
    {{ (item?.settings?.shorten ? item.settings!.shortenName : item.text) || item.pl }}
  }
</ng-template>

<ng-template #DrawerTitleRef>
  <div class="dashboard-sidebar-title">
    Social Info Settings
    <i nz-icon nzType="close" nzTheme="outline" (click)="onCloseSidebar()"></i>
  </div>
</ng-template>
